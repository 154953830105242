import { Link, useOutletContext } from "react-router-dom";
import { BlogData } from "../../../lib/blogHelpers";
import styles from "./Blog.module.css";
import dayjs from "dayjs";
import { Heading } from "../../../components/Heading/Heading";

export const BlogContents = () => {
  const { data } = useOutletContext<BlogData>();

  return (
    <>
      {data.map((post) => (
        <div className={styles.main} key={post.id}>
          <div>
            Posted on {dayjs(post.attributes.date).format("ll")} by{" "}
            {post.attributes.author.data.attributes.name}
          </div>
          <Link
            className={styles.titleLink}
            to={`/blog/${post.attributes.slug}`}
          >
            <Heading size={2} className={styles.title}>
              {post.attributes.title}
            </Heading>
          </Link>
          <p>{post.attributes.preview}</p>
          <Link
            className={styles.readMore}
            to={`/blog/${post.attributes.slug}`}
          >
            Read more...
          </Link>
        </div>
      ))}
    </>
  );
};
