import { FormField, FormFieldProps, Pane, TextInput } from "evergreen-ui";
import americanFlag from "./images/american_flag.png";
import { useGFTheme } from "../../lib/themes";

interface PhoneFieldProps extends Omit<FormFieldProps, "type"> {
  value?: string;
  name?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PhoneField = ({ value, onChange, ...props }: PhoneFieldProps) => {
  const { colors } = useGFTheme();
  return (
    <FormField {...props}>
      <Pane
        display="flex"
        borderWidth="2px"
        borderColor={colors.greenDark}
        borderStyle="solid"
        borderRadius="5px"
        alignItems="center"
        height="2.875rem"
        is="label"
      >
        <img
          src={americanFlag}
          alt="United States"
          style={{
            width: 34,
            height: 21,
            padding: "0 0.8125rem",
            boxSizing: "content-box",
          }}
        />
        <Pane
          paddingLeft="0.8125rem"
          borderLeftWidth="1px"
          borderLeftStyle="solid"
          height="2.875rem"
          lineHeight="2.875rem"
          borderColor={colors.greenDark}
        >
          +1
        </Pane>
        <TextInput
          type="tel"
          value={value}
          onChange={onChange}
          className="phone-input"
          name="phone"
          border="none"
          width="10.326rem"
          backgroundColor="transparent"
          minWidth={0}
          placeholder="(000) 000 - 0000"
          selectors={{
            "&:focus": { outline: "none" },
            "&.phone-input": { border: "none" },
          }}
        />
      </Pane>
    </FormField>
  );
};
