import { UserWithMediaAndGroups } from "@greenflagdate/shared";
import { StoreSchema, TransitionType } from "./StoreSchema";
import { apiReq } from "../apiReq";
import { LOGIN_TOKEN_KEY, SPARK_REGISTRATION_KEY } from "./store";
import posthog from "posthog-js";

export enum ActionType {
  SetSafeArea = "SetSafeArea",
  SetLoading = "SetLoading",
  UpdateAuth = "UpdateAuth",
  Login = "Login",
  LogOut = "LogOut",
  SetTransitionType = "SetTransitionType",
  UpdateUser = "UpdateUser",
  UpdateSparkRegistration = "UpdateSparkRegistration",
}

interface ActionMeta {
  [ActionType.SetSafeArea]: {
    params: {
      top: number;
      right: number;
      bottom: number;
      left: number;
    };
    return: void;
  };
  [ActionType.SetLoading]: {
    params: {
      loading: boolean;
    };
    return: void;
  };
  [ActionType.UpdateAuth]: {
    params: Partial<StoreSchema["auth"]>;
    return: void;
  };
  [ActionType.Login]: {
    params: {
      token: string;
      user: UserWithMediaAndGroups;
    };
    return: void;
  };
  [ActionType.LogOut]: {
    params: Record<string, never>;
    return: void;
  };
  [ActionType.SetTransitionType]: {
    params: {
      type: TransitionType;
    };
    return: void;
  };
  [ActionType.UpdateUser]: {
    params: {
      user?: UserWithMediaAndGroups;
    };
    return: void;
  };
  [ActionType.UpdateSparkRegistration]: {
    params: Partial<StoreSchema["sparkRegistration"]>;
    return: void;
  };
}

export const handlers: ActionHandlers = {
  [ActionType.SetSafeArea](params, store) {
    store.safeArea = { ...params };
  },
  [ActionType.SetLoading](params, store) {
    store.loading = params.loading;
  },
  [ActionType.UpdateAuth](params, store) {
    store.auth = { ...store.auth, ...params };
  },
  [ActionType.Login](params, store) {
    store.auth.token = params.token;
    store.user = { ...params.user };
    apiReq.headers.authorization = `Bearer ${params.token}`;
    localStorage.setItem(LOGIN_TOKEN_KEY, params.token);
    posthog.identify(params.user.id.toString());
  },
  [ActionType.LogOut](_params, store) {
    store.auth = {};
    store.user = undefined;
    delete apiReq.headers.authorization;
    localStorage.removeItem(LOGIN_TOKEN_KEY);
  },
  [ActionType.SetTransitionType](params, store) {
    store.transitionType = params.type;
  },
  [ActionType.UpdateUser](params, store) {
    store.user = params.user;
  },
  [ActionType.UpdateSparkRegistration](params, store) {
    store.sparkRegistration = { ...store.sparkRegistration, ...params };
    localStorage.setItem(
      SPARK_REGISTRATION_KEY,
      JSON.stringify(store.sparkRegistration)
    );
  },
};

export interface GenericAction<T extends ActionType> {
  type: T;
  params: ActionMeta[T]["params"];
}

export type GenericHandler<T extends ActionType> = (
  params: ActionMeta[T]["params"],
  store: StoreSchema
) => ActionMeta[T]["return"];

export type Action = {
  [K in keyof typeof ActionType]: GenericAction<(typeof ActionType)[K]>;
}[keyof typeof ActionType];

export type ActionHandlers = {
  [K in ActionType]: GenericHandler<K>;
};
