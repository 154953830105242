import EventEmitter from "eventemitter3";
import { enableMapSet, produce } from "immer";
import {
  ModuleAttributes,
  QuestionsSectionAttributes,
} from "./quizDataHelpers";
import posthog from "posthog-js";
import {
  CMSItem,
  mergeProfile,
  profileReplacer,
  profileReviver,
  PublicUser,
  UserWithMedia,
} from "@greenflagdate/shared";
import { apiReq } from "./apiReq";
import { ReqMethod } from "@larner.dev/req";

enableMapSet();

export type UserWithMediaAndProfile = Omit<UserWithMedia, "profile"> & {
  profile: Map<number, SchemaModule>;
};

export type PublicUserWithProfile = Omit<PublicUser, "profile"> & {
  profile: Map<number, SchemaModule>;
};

export interface SchemaVideoStats {
  playTime: number;
  waitTime: number;
  currentTime: number;
}

export interface SchemaQuestion {
  id: number;
  answerId?: number;
  explain?: string;
  public?: boolean;
}

export interface SchemaSection {
  id: number;
  completed: boolean;
  videoStats?: SchemaVideoStats;
  questions?: Map<number, SchemaQuestion>;
  private?: boolean;
  outcomeId?: number;
}

export interface SchemaModule {
  id: number;
  completed: boolean;
  sections: Map<number, SchemaSection>;
}

export interface SchemaData {
  modules: Map<number, SchemaModule>;
  user?: UserWithMediaAndProfile;
  credentialId?: number;
  token?: string;
  loaded: boolean;
}

export const generateEmptySchemaData = (): SchemaData => ({
  modules: new Map<number, SchemaModule>(),
  loaded: false,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function replacer(_key: string, value: any) {
  if (value instanceof Map) {
    return {
      dataType: "Map",
      value: Array.from(value.entries()), // or with spread: value: [...value]
    };
  } else {
    return value;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function reviver(_key: string, value: any) {
  if (typeof value === "object" && value !== null) {
    if (value.dataType === "Map") {
      return new Map(value.value);
    }
  }
  return value;
}

export class Schema extends EventEmitter {
  public data: SchemaData = generateEmptySchemaData();
  private saving: boolean = false;
  private saveAgain: boolean = false;

  constructor() {
    super();
    if (typeof window !== "undefined") {
      const savedDataString = window.localStorage.getItem("store");
      if (savedDataString) {
        const localData: SchemaData = JSON.parse(savedDataString, reviver);
        this.update({ ...localData, loaded: false });
        if (localData.token) {
          apiReq.headers.authorization = `Bearer ${localData.token}`;
          apiReq
            .standardRequest<UserWithMedia>("/user/me", ReqMethod.GET)
            .then((user) => {
              const profile = JSON.parse(user.profile, profileReviver);
              this.update({
                ...this.data,
                user: { ...user, profile },
                modules: profile,
                loaded: true,
              });
            });
        } else {
          this.update({ ...this.data, ...localData, loaded: true });
        }
      } else {
        this.update({ ...generateEmptySchemaData(), loaded: true });
      }
    }
  }

  save() {
    if (this.saving) {
      this.saveAgain = true;
    } else {
      this.saving = true;
      apiReq[ReqMethod.PUT]("/user/attributes", {
        profile: JSON.stringify(this.data.modules, replacer),
      }).then(() => {
        this.saving = false;
        if (this.saveAgain) {
          this.saveAgain = false;
          this.save();
        }
      });
    }
  }

  update(data: SchemaData) {
    if (this.data !== data) {
      const profileChanged = this.data.modules !== data.modules;
      this.data = data;
      const dataString = JSON.stringify(data, replacer);
      window.localStorage.setItem("store", dataString);
      if (this.data.token && profileChanged) {
        apiReq.headers.authorization = `Bearer ${this.data.token}`;
        this.save();
      }
      this.emit("change");
    }
  }

  updateProfile(modules: Map<number, SchemaModule>) {
    this.data = { ...this.data, modules: modules };
    const dataString = JSON.stringify(this.data, replacer);
    window.localStorage.setItem("store", dataString);
    if (this.data.token) {
      apiReq.headers.authorization = `Bearer ${this.data.token}`;
      this.save();
    }
    this.emit("change");
  }

  updateModule(moduleId: number, data: Partial<SchemaModule>) {
    const newData = produce(this.data, (draft) => {
      let m = draft.modules.get(moduleId);
      if (!m) {
        m = {
          id: moduleId,
          completed: false,
          sections: new Map<number, SchemaSection>(),
        };
        draft.modules.set(moduleId, m);
      }
      m = { ...m, ...data };
      draft.modules.set(moduleId, m);
    });

    this.update(newData);
  }

  updateSectionVideoState(
    moduleId: number,
    sectionId: number,
    playTime: number,
    waitTime: number,
    currentTime: number
  ) {
    const newData = produce(this.data, (draft) => {
      let m = draft.modules.get(moduleId);
      if (!m) {
        m = {
          id: moduleId,
          completed: false,
          sections: new Map<number, SchemaSection>(),
        };
        draft.modules.set(moduleId, m);
      }
      let s = m.sections.get(sectionId);
      if (!s) {
        s = {
          id: sectionId,
          completed: false,
        };
        m.sections.set(sectionId, s);
      }
      m.sections.set(sectionId, {
        ...s,
        videoStats: {
          playTime,
          waitTime,
          currentTime,
        },
      });
    });

    this.update(newData);
  }

  updateSection(
    sectionId: number,
    mod: CMSItem<ModuleAttributes>,
    data: Partial<SchemaSection>
  ) {
    const newData = produce(this.data, (draft) => {
      let m = draft.modules.get(mod.id);
      if (!m) {
        m = {
          id: mod.id,
          completed: false,
          sections: new Map<number, SchemaSection>(),
        };
        draft.modules.set(mod.id, m);
      }
      let s = m.sections.get(sectionId);
      if (!s) {
        s = {
          id: sectionId,
          completed: false,
        };
        m.sections.set(sectionId, s);
      }
      m.sections.set(sectionId, {
        ...s,
        ...data,
      });
      const completedStateBefore = m.completed;
      m.completed = mod.attributes.sections.data.every(
        (s) => m?.sections.get(s.id)?.completed
      );
      if (m.completed && !completedStateBefore) {
        posthog.capture("moduleCompleted", {
          id: m.id,
        });
      }
    });

    this.update(newData);
  }

  updateQuestion(
    mod: CMSItem<ModuleAttributes>,
    section: CMSItem<QuestionsSectionAttributes>,
    questionId: number,
    data: Partial<SchemaQuestion>
  ) {
    const newData = produce(this.data, (draft) => {
      let m = draft.modules.get(mod.id);
      if (!m) {
        m = {
          id: mod.id,
          completed: false,
          sections: new Map<number, SchemaSection>(),
        };
        draft.modules.set(mod.id, m);
      }
      let s = m.sections.get(section.id);
      if (!s) {
        s = {
          id: section.id,
          completed: false,
          questions: new Map<number, SchemaQuestion>(),
        };
        m.sections.set(section.id, s);
      }
      if (!s.questions) {
        s.questions = new Map<number, SchemaQuestion>();
        // m.sections.set(sectionId, s);
      }
      let q = s.questions?.get(questionId);
      if (!q) {
        q = {
          id: questionId,
        };
        s.questions.set(questionId, q);
      }
      s.questions.set(questionId, {
        ...q,
        ...data,
      });

      const sectionCompletedStateBefore = s.completed;
      s.completed = !!section.attributes.questions.data.every((q) => {
        return q.attributes.type === "multiple-choice"
          ? !!s?.questions?.get(q.id)?.answerId
          : !!s?.questions?.get(q.id)?.explain;
      });

      if (s.completed && !sectionCompletedStateBefore) {
        posthog.capture("sectionCompleted", {
          id: s.id,
          type: "questions",
        });
      }

      const moduleCompletedStateBefore = s.completed;
      m.completed = mod.attributes.sections.data.every(
        (s) => m?.sections.get(s.id)?.completed
      );

      if (m.completed && !moduleCompletedStateBefore) {
        posthog.capture("moduleCompleted", {
          id: m.id,
        });
      }
    });

    this.update(newData);
  }

  setUser(user: UserWithMedia) {
    const newData = produce(this.data, (draft) => {
      const profile = JSON.parse(
        mergeProfile(
          user.profile,
          JSON.stringify(this.data.modules, profileReplacer)
        ),
        profileReviver
      );
      draft.user = { ...user, profile };
      draft.modules = profile;
    });
    posthog.identify(user.id.toString(), {
      email: user.email,
      name: user.first_name,
      slug: user.slug,
    });

    this.update(newData);
  }

  logOut() {
    const newData = produce(this.data, (draft) => {
      delete draft.user;
      delete draft.credentialId;
      delete draft.token;
    });

    this.update(newData);
  }

  setCredentialId(credentialId?: number) {
    const newData = produce(this.data, (draft) => {
      if (credentialId === undefined) {
        delete draft.credentialId;
      } else {
        draft.credentialId = credentialId;
      }
    });

    this.update(newData);
  }

  setToken(token?: string) {
    const newData = produce(this.data, (draft) => {
      draft.token = token;
      apiReq.headers.authorization = `Bearer ${token}`;
    });

    this.update(newData);
  }
}
