import dayjs, { ConfigType, Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { getMondayForSpark } from "@greenflagdate/shared";

const getSparkDates = (now: ConfigType = undefined) => {
  const wed = getMondayForSpark(now);
  const options = [];
  for (let i = 0; i < 7; i++) {
    const day = wed.add(i, "days");
    if (i === 5 || i === 6) {
      options.push(day.set("hour", 11));
      options.push(day.set("hour", 18));
    }
    options.push(day.set("hour", 19));
  }
  return options;
};

export const useSparkDates = (now: string | undefined = undefined) => {
  const [dates, setDates] = useState<Dayjs[]>([]);

  useEffect(() => {
    setDates(getSparkDates(now));
    const msUntilTomorrow = dayjs(now).diff(
      dayjs(now).add(1, "day").startOf("day"),
      "milliseconds"
    );
    if (!now) {
      setTimeout(() => {
        setDates(getSparkDates());
      }, msUntilTomorrow);
    }
  }, [now]);

  return dates;
};
