import { useEffect } from "react";

class BackgroundColor extends EventTarget {
  public val: string = "";
}

export const backgroundColor = new BackgroundColor();

export const useBackgroundColor = (color: string) => {
  useEffect(() => {
    backgroundColor.val = color;
    backgroundColor.dispatchEvent(
      new CustomEvent<{ color: string }>("update", { detail: { color } })
    );
  }, [color]);
};
