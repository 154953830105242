import { IncomingNativeEvent, IncomingNativeEventType } from "./NativeEvent";
import { useEffect, useState } from "react";

declare global {
  interface Window {
    platform?: "ios" | "android" | "web";
    webkit?: {
      messageHandlers: {
        actionHandler: {
          postMessage: (message: string) => void;
        };
      };
    };
    Android?: {
      fromWebView: (message: string) => void;
    };
    safeArea: { top: number; left: number; bottom: number; right: number };
  }
}

export const platform = window.platform || "web";

export const isIos =
  window.location.host.startsWith("ios.") || window.platform === "ios";

export const isAndroid =
  window.location.host.startsWith("android.") || window.platform === "android";

interface SafeAreaValue {
  top: number;
  right: number;
  bottom: number;
  left: number;
}
class SafeArea extends EventTarget {
  private val: SafeAreaValue = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  };

  public get top(): number {
    return this.val.top;
  }
  public get right(): number {
    return this.val.right;
  }
  public get bottom(): number {
    return this.val.bottom;
  }
  public get left(): number {
    return this.val.left;
  }
  public set(val: SafeAreaValue, emit = true) {
    let updated = false;
    if (val.top !== this.val.top) {
      updated = true;
      this.val.top = val.top;
    }
    if (val.right !== this.val.right) {
      updated = true;
      this.val.right = val.right;
    }
    if (val.bottom !== this.val.bottom) {
      updated = true;
      this.val.bottom = val.bottom;
    }
    if (val.left !== this.val.left) {
      updated = true;
      this.val.left = val.left;
    }
    if (updated && emit) {
      this.dispatchEvent(
        new CustomEvent("updated", {
          detail: val,
        })
      );
    }
  }
}

export const safeArea = new SafeArea();
// safeArea.addEventListener("updated", (event: Event) => {
//   const { detail } = event as CustomEvent<SafeAreaValue>;
//   document.body.style.paddingTop = detail.top + "px";
//   document.body.style.paddingRight = detail.right + "px";
//   document.body.style.paddingBottom = detail.bottom + "px";
//   document.body.style.paddingLeft = detail.left + "px";
//   console.log("safe area listener");
// });
safeArea.set(
  window.safeArea || { top: 20, right: 0, bottom: 0, left: 0 },
  false
);

export const useSafeArea = () => {
  const [safeAreaVal, setSafeAreaVal] = useState<SafeAreaValue>({
    top: safeArea.top,
    right: safeArea.right,
    bottom: safeArea.bottom,
    left: safeArea.left,
  });
  useEffect(() => {
    function handleUpdate(event: Event) {
      setSafeAreaVal((event as CustomEvent<SafeAreaValue>).detail);
    }
    safeArea.addEventListener("updated", handleUpdate);
    return () => safeArea.removeEventListener("updated", handleUpdate);
  }, []);
  return safeAreaVal;
};

export const handleIncomingNativeEvent = (event: IncomingNativeEvent) => {
  switch (event.type) {
    case IncomingNativeEventType.setSafeArea:
      safeArea.set(event);
      break;
  }
};
