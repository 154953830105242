import { PropsWithChildren } from "react";
import { Pane, PaneProps } from "evergreen-ui";
import { useGFTheme } from "../../lib/themes";

export function LinkButton(props: PropsWithChildren<PaneProps<"button">>) {
  const { colors } = useGFTheme();
  return (
    <Pane
      textDecoration="underline"
      display="inline-block"
      cursor={props.disabled ? "default" : "pointer"}
      color={props.disabled ? colors.neutralDark : colors.bluePrimary}
      fontWeight="normal"
      fontSize="1rem"
      selectors={{
        "&:hover": {
          color: props.disabled ? colors.neutralDark : colors.blueLight,
        },
      }}
      {...props}
    />
  );
}
