import { FormEventHandler, useState } from "react";
import styles from "./Contact.module.css";
import isEmail from "validator/lib/isEmail";
import { Button } from "../../../components/Button/Button";
import { Card } from "../../../components/Card/Card";
import { Heading } from "../../../components/Heading/Heading";
import { Section } from "../Index/Section";
import { TextField } from "../../../components/TextField/TextField";
import { useReq } from "@larner.dev/use-req";
import { apiReq } from "../../../lib/apiReq";

interface Errors {
  email?: string;
  message?: string;
  default?: string;
}

export function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState<Errors>({});
  const [request, requestState] = useReq(apiReq);

  const contact: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const errors: Errors = {};
    if (!email || !isEmail(email)) {
      errors.email = "Please double check the email";
    }
    if (!message) {
      errors.message = "What message would you like to send?";
    }
    setErrors(errors);
    if (!Object.keys(errors).length) {
      requestState.clearError();
      const result = await request.post("/contact", {
        name,
        email,
        message,
      });
      if (!result) {
        setErrors({
          default: "Something unexpected went wrong. Please try again.",
        });
      }
    }
  };

  return (
    <div className={styles.content}>
      <Section theme="primary">
        <Section.Title className={styles.title}>Contact</Section.Title>
        <div className={styles.contactWrapper}>
          <Card className={styles.contact}>
            {requestState.result ? (
              <div className={styles.contactForm}>
                <Heading
                  size={1}
                  style={{ marginBottom: "1rem", marginTop: "2rem" }}
                >
                  We have received your message!
                </Heading>
                <div
                  className={styles.contactFormDescription}
                  style={{ marginBottom: "2rem" }}
                >
                  Thanks for getting in touch. We&apos;ll get back to you via
                  the email you provided in the next 48 hours.
                </div>
              </div>
            ) : (
              <form className={styles.contactForm} onSubmit={contact}>
                <div className={styles.contactFormDescription}>
                  Whether you&apos;ve got a question, want to chat with the
                  founders, or are interested in joining our board of
                  therapists, we&apos;re excited to hear from you!
                </div>
                {errors.default && (
                  <div className={styles.defaultError}>{errors.default}</div>
                )}
                <TextField
                  size="large"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.currentTarget.value)}
                  fullWidth
                />
                <TextField
                  size="large"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  fullWidth
                  error={errors.email}
                />
                <TextField
                  size="large"
                  placeholder="Message"
                  value={message}
                  onChange={(e) => setMessage(e.currentTarget.value)}
                  multiLine
                  fullWidth
                  error={errors.message}
                />
                <Button label="SUBMIT" size="medium" fullWidth type="submit" />
              </form>
            )}
          </Card>
        </div>
      </Section>
    </div>
  );
}
