import classNames from "classnames";
import { MouseEventHandler, useMemo, useState } from "react";
import { Hamburger } from "./Hamburger";
import styles from "./MarketingHeader.module.css";
import logo from "./images/logo.png";
import { Button } from "../Button/Button";
import posthog from "posthog-js";
import { useSchemaStore } from "../../lib/useSchemaStore";
import { Link, useNavigate } from "react-router-dom";
import { getCurrentSparkPage } from "../../lib/getCurrentSparkPage";
import { store, useStoreData } from "../../lib/store/store";
import { isLoggedIn } from "../../lib/isLoggedIn";
import { ActionType } from "../../lib/store/storeActions";
import { LoginDialog } from "../LoginDialog/LoginDialog";

export function MarketingHeader() {
  const [navOpen, setNavOpen] = useState(false);
  const oldStore = useSchemaStore();
  const storeData = useStoreData();
  const navigate = useNavigate();
  const loggedIn = useMemo(() => isLoggedIn(storeData), [storeData]);
  const [loginDialogShown, setLoginDialogShown] = useState(false);

  const onNavClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    setNavOpen(false);
    const url = new URL(e.currentTarget.href);
    if (url.pathname === "/") {
      const el = document.getElementById(url.hash.substring(1));
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
        e.preventDefault();
      }
    }
  };
  const onSparkClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    navigate(getCurrentSparkPage());
  };
  return (
    <div className={styles.content}>
      <div className={styles.logoSpacer}>
        <Link to="/" className={styles.logoLink}>
          <img src={logo} alt="Green Flag logo" width={150} />
        </Link>
      </div>

      <div className={styles.storeLinks}>
        {!oldStore.token ||
          (!oldStore.user?.slug && (
            <Button
              label="Get Started"
              href={getCurrentSparkPage()}
              size={"medium"}
              onClick={() =>
                posthog.capture("marketingHeaderCtaClick", {
                  text: "Get Started",
                })
              }
            />
          ))}

        {/* <Link to="/sign-up?utm_source=app_store" className={styles.appStore}>
          <Image src={appStore} alt="Download on the App Store" />
        </Link>
        <Link
          href="/sign-up?utm_source=play_store"
          className={styles.playStore}
        >
          <Image src={playStore} alt="Get it on Google Play" height={40} />
        </Link> */}
      </div>
      <Hamburger
        open={navOpen}
        onClick={setNavOpen}
        className={styles.hamburger}
      />
      <div
        className={classNames(styles.navLinks, { [styles.navOpen]: navOpen })}
      >
        <Link to="/events" onClick={onNavClick}>
          EVENTS
        </Link>
        <Link to="/app/spark" onClick={onSparkClick}>
          SPARK
        </Link>
        <Link to="/team" onClick={onNavClick}>
          TEAM
        </Link>
        <Link to="/contact" onClick={onNavClick}>
          CONTACT
        </Link>
        {!loggedIn ? (
          <Link
            to="#"
            onClick={(e) => {
              setLoginDialogShown(true);
              onNavClick(e);
            }}
            className={styles.lastTextNavLink}
          >
            SIGN IN
          </Link>
        ) : (
          <Link
            to="#"
            onClick={(e) => {
              store.dispatch({
                type: ActionType.LogOut,
                params: {},
              });
              onNavClick(e);
            }}
            className={styles.lastTextNavLink}
          >
            SIGN OUT
          </Link>
        )}

        {!loggedIn && (
          <Button
            label="Get Started"
            href={getCurrentSparkPage()}
            size={"medium"}
            className={styles.getStarted}
            onClick={() =>
              posthog.capture("marketingHeaderCtaClick", {
                text: "Get Started",
              })
            }
          />
        )}
      </div>
      <LoginDialog
        isShown={loginDialogShown}
        onClose={() => setLoginDialogShown(false)}
      />
    </div>
  );
}
