import { useCallback, useEffect, useState } from "react";
import { SchemaData, generateEmptySchemaData } from "./Schema";
import { schemaStore } from "./schemaStore";

export const useSchemaStore = (): SchemaData => {
  const [data, setData] = useState(generateEmptySchemaData());
  const onChange = useCallback(() => {
    setData(schemaStore.data);
  }, [setData]);

  useEffect(() => {
    schemaStore.addListener("change", onChange);
    setData(schemaStore.data);
    return () => {
      schemaStore.removeListener("change", onChange);
    };
  }, [onChange]);

  return data;
};
