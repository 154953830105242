import { Button } from "../../../components/Button/Button";
import { Paragraph } from "evergreen-ui";
import { H2 } from "../../../components/Heading/Heading";
import styles from "./Event.module.css";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { PageHeader } from "../../../components/PageHeader/PageHeader";
import { CMSEventAttributes, CMSItem } from "@greenflagdate/shared";
import { Link } from "../../../components/Link/Link";

export function EventSuccess() {
  const { event } = useOutletContext<{ event: CMSItem<CMSEventAttributes> }>();
  const [searchParams] = useSearchParams();

  return (
    <div className={styles.gfc}>
      <PageHeader>
        <PageHeader.Title whiteSpace="pre-wrap">
          {event.attributes.title}
        </PageHeader.Title>
      </PageHeader>
      <div className={styles.mainContent}>
        {searchParams.get("waitlist") ? (
          <>
            <H2>You&apos;re on the wait list!</H2>
            <Paragraph size={500}>
              You've successfully registered for {event.attributes.title}{" "}
              <strong>wait list</strong>.
            </Paragraph>

            <Paragraph size={500}>
              We&apos;ll let you know if any spots open up. Need Help?{" "}
              <Link to="/contact">Contact Us</Link>
            </Paragraph>
          </>
        ) : (
          <>
            <H2>You&apos;re in!</H2>
            <Paragraph size={500}>
              You've successfully registered for {event.attributes.title}.
            </Paragraph>

            <Paragraph size={500}>
              Be on the look out for an email to your inbox with more details.{" "}
              <strong>
                If you don't get one please check your spam folder.
              </strong>{" "}
              Need Help? <Link to="/contact">Contact Us</Link>.
            </Paragraph>
          </>
        )}

        <Paragraph size={500}>In the mean time, maybe you want to...</Paragraph>
        <Button
          size="large"
          label="Check Out Other Events"
          href="/events"
          style={{ margin: "2rem auto 0 auto" }}
        />
      </div>
    </div>
  );
}
