import { PropsWithChildren } from "react";
import { Pane, PaneProps } from "evergreen-ui";
import { To, Link as RRLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useGFTheme } from "../../lib/themes";

interface LinkProps extends PropsWithChildren<PaneProps<"a">> {
  to: To;
  component?: "link" | "hash-link";
}

export function Link({ to, component, children, ...props }: LinkProps) {
  const { colors } = useGFTheme();
  return (
    <Pane
      textDecoration="underline"
      display="inline-block"
      color={colors.bluePrimary}
      selectors={{ "&:hover": { color: colors.blueLight } }}
      {...props}
    >
      {props.target?.toLowerCase() === "_blank" ? (
        <a href={to.toString()} target="_blank">
          {children}
        </a>
      ) : component === "hash-link" ? (
        // @ts-expect-error type issues
        <HashLink {...props} to={to}>
          {children}
        </HashLink>
      ) : (
        <RRLink to={to}>{children}</RRLink>
      )}
    </Pane>
  );
}
