import classNames from "classnames";
import { Button } from "../../../components/Button/Button";
import { Card } from "../../../components/Card/Card";
import { OldDateField } from "../../../components/DateField/OldDateField";
import { Heading } from "../../../components/Heading/Heading";
import { TextField } from "../../../components/TextField/TextField";
import styles from "./Newsletter.module.css";
import { useRef, useState, FormEventHandler, useCallback } from "react";
import validator from "validator";
import posthog from "posthog-js";
export const Newsletter = () => {
  const form = useRef<HTMLFormElement>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [date, setDate] = useState("");
  const submit: FormEventHandler<HTMLFormElement> = useCallback((e) => {
    if (form.current) {
      const errors: Record<string, string> = {};
      const data = new FormData(form.current);
      if (!data.get("email")) {
        errors.email = "Please enter your email";
      } else if (!validator.isEmail(data.get("email")?.toString() || "")) {
        errors.email = "Please check your email";
      }
      if (Object.keys(errors).length) {
        setErrors(errors);
        e.preventDefault();
      } else {
        posthog.capture("newsletterSignUp", {
          $set: { email: data.get("email") },
        });
      }
    } else {
      e.preventDefault();
    }
  }, []);
  return (
    <div className={styles.formContainer}>
      <div className={styles.formContent}>
        <Card className={styles.formCard}>
          <Heading size={2} className={styles.title}>
            Subscribe to the Green Flag Newsletter
          </Heading>
          <p>
            Get bi-weekly emails on dating, mental health and Green Flag events!
          </p>

          <p>
            All fields are optional except for your email address but the more
            information you provide the more relevant newsletters we can send
            you.
          </p>

          <form
            action="https://customerioforms.com/forms/submit_action?site_id=24a148f7ff1ee70c7a7b&form_id=website_newsletter&success_url=https://www.greenflagdate.com/newsletter/success"
            method="POST"
            ref={form}
            onSubmit={submit}
          >
            <label className={styles.question}>
              <div className={styles.questionLabel}>
                Email <span className={styles.required}>*</span>
              </div>
              <TextField fullWidth name="email" error={errors.email} />
            </label>
            <label className={styles.question}>
              <div className={styles.questionLabel}>Birth Date</div>
              <OldDateField
                fullWidth
                onChange={setDate}
                value={date}
                error={errors.birth_date}
                name="birth_date"
              />
            </label>
            <label className={styles.question}>
              <div className={styles.questionLabel}>Zip Code</div>
              <TextField
                fullWidth
                type="number"
                name="postal_code"
                error={errors.postal_code}
              />
            </label>
            <div className={classNames(styles.group, styles.question)}>
              <div className={styles.questionLabel}>
                Which most closely describes your gender?{" "}
              </div>
              <label>
                <input type="radio" name="gender" value="woman" />{" "}
                <div>Woman</div>
              </label>
              <label>
                <input type="radio" name="gender" value="man" /> <div>Man</div>
              </label>
              <label>
                <input type="radio" name="gender" value="transgender woman" />{" "}
                <div>Transgender Woman</div>
              </label>
              <label>
                <input type="radio" name="gender" value="transgender man" />{" "}
                <div>Transgender Man</div>
              </label>
              <label>
                <input type="radio" name="gender" value="non-binary" />{" "}
                <div>Non-Binary</div>
              </label>
              <label>
                <input type="radio" name="gender" value="agender" />{" "}
                <div>Agender/I don&apos;t identify with any gender</div>
              </label>
              <label>
                <input type="radio" name="gender" value="prefer not to say" />{" "}
                <div>Prefer Not to State</div>
              </label>
              <label>
                <input type="radio" name="gender" value="other" />{" "}
                <TextField
                  type="text"
                  placeholder="Other"
                  name="gender_other"
                />
              </label>
              {errors.gender && (
                <div className={styles.error}>{errors.gender}</div>
              )}
            </div>
            <div className={classNames(styles.group, styles.question)}>
              <div className={styles.questionLabel}>
                Who do you want to date?
              </div>

              <label>
                <input type="checkbox" name="dating_preference" value="woman" />{" "}
                <div>Woman</div>
              </label>
              <label>
                <input type="checkbox" name="dating_preference" value="man" />{" "}
                <div>Man</div>
              </label>
              <label>
                <input
                  type="checkbox"
                  name="dating_preference"
                  value="non-binary"
                />{" "}
                <div>Non-Binary</div>
              </label>
              <label>
                <input
                  type="checkbox"
                  name="dating_preference"
                  value="everyone"
                />{" "}
                <div>I&apos;m open to dating everyone</div>
              </label>
              {errors.dating_preference && (
                <div className={styles.error}>{errors.dating_preference}</div>
              )}
            </div>
            <Button
              label="SUBSCRIBE"
              size="large"
              type="submit"
              className={styles.button}
            />
          </form>
        </Card>
      </div>
    </div>
  );
};
