import { PropsWithChildren, ReactNode } from "react";
import { Pane, PaneProps } from "evergreen-ui";
import { useGFTheme } from "../../lib/themes";

interface TagProps extends PropsWithChildren<PaneProps<"div">> {
  iconBefore?: ReactNode;
  iconAfter?: ReactNode;
}

export function Tag({ iconBefore, iconAfter, children, ...props }: TagProps) {
  const { colors } = useGFTheme();
  return (
    <Pane
      display="inline-flex"
      gap="0.525rem"
      paddingX="0.5rem"
      height="1.5rem"
      borderRadius="5px"
      alignItems="center"
      {...props}
    >
      {iconBefore}
      <Pane
        fontFamily="work-sans"
        fontSize="0.75rem"
        lineHeight="0.75rem"
        fontWeight="bold"
        color={colors.orangeDark}
      >
        {children}
      </Pane>
      {iconAfter}
    </Pane>
  );
}
