import { FormEvent, MouseEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import posthog from "posthog-js";
import { schemaStore } from "../../../lib/schemaStore";
import { useSchemaStore } from "../../../lib/useSchemaStore";
import { Button } from "../../../components/Button/Button";
import { Loader } from "../../../components/Loader/Loader";
import { TextField } from "../../../components/TextField/TextField";

import styles from "./SignIn.module.css";
import { Card } from "../../../components/Card/Card";
import { Alert } from "../../../components/Alert/Alert";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { apiReq } from "../../../lib/apiReq";
import { useReq } from "@larner.dev/use-req";
import { store as newStore } from "../../../lib/store/store";
import {
  PublicCredentialRecord,
  UserWithMediaAndGroups,
} from "@greenflagdate/shared";
import { ActionType } from "../../../lib/store/storeActions";

interface Errors {
  email?: string;
  code?: string;
  default?: string;
}

type SaveType = (e?: FormEvent<HTMLFormElement>) => Promise<void>;
type VerifyType = (e?: FormEvent<HTMLFormElement>) => Promise<void>;

export function SignIn() {
  const store = useSchemaStore();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [redirecting, setRedirecting] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const navigate = useNavigate();

  const [loginRequest, loginRequestState] =
    useReq<PublicCredentialRecord>(apiReq);
  const [verifyRequest, verifyRequestState] = useReq<{
    token: string;
    user: UserWithMediaAndGroups;
  }>(apiReq);
  const login: SaveType = async (e) => {
    if (e) {
      e.preventDefault();
    }
    const errors: Errors = {};
    if (!email || !isEmail(email)) {
      errors.email = "Please double check the email";
    }
    setErrors(errors);
    if (!Object.keys(errors).length) {
      loginRequestState.clearError();
      const response = await loginRequest.post("/user", {
        email,
      });
      if (!response.success) {
        setErrors({
          default: "We couldn't find that email.",
        });
      } else {
        const { result } = response;
        schemaStore.setCredentialId(result.id);
      }
    }
  };

  const verify: VerifyType = async (e) => {
    e?.preventDefault();
    posthog.capture("verifyEmailClick");
    const errors: Errors = {};
    if (!code) {
      errors.code = "Please enter the code that was emailed to you.";
    }
    setErrors(errors);
    if (!Object.keys(errors).length) {
      verifyRequestState.clearError();
      const response = await verifyRequest.post("/user/verify", {
        token: `${code}.${store.credentialId}`,
        type: "ReadableToken",
      });
      if (!response.success) {
        posthog.capture("verifyEmailError");
        setErrors({
          default: "Something unexpected went wrong. Please try again.",
        });
      } else {
        const { result } = response;
        posthog.capture("verifyEmailSuccess");
        setRedirecting(true);
        schemaStore.setToken(result.token);
        schemaStore.setUser(result.user);
        newStore.dispatch({
          type: ActionType.Login,
          params: response.result,
        });

        const urlParams = new URLSearchParams(window.location.search);
        const redirect = urlParams.get("r");
        navigate(redirect || `/${result.user.slug}`);
      }
    }
  };

  const resend: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    const response = await loginRequest.post("/user", {
      email,
    });
    if (!response.success) {
      setErrors({
        default: "Something unexpected went wrong. Please try again.",
      });
    } else {
      schemaStore.setCredentialId(response.result.id);
    }
  };

  if (store.token && store.user && !redirecting) {
    return (
      <div className={styles.container}>
        <Card className={styles.card}>
          <div className={styles.container}>
            <div className={styles.header}>
              <Card.Header className={styles.header}>
                <div style={{ paddingRight: "1rem" }}>
                  You&apos;re already logged in
                </div>
              </Card.Header>
            </div>
            <div>
              <strong
                style={{
                  padding: "2rem",
                  display: "block",
                  textAlign: "center",
                }}
              >
                You&apos;re already logged in as {store.user.email}
              </strong>
            </div>
            <div className={styles.footer}>
              <Button
                type="button"
                label="Log in with a different email"
                size="large"
                loading={verifyRequestState.loading}
                fullWidth
                onClick={() => schemaStore.logOut()}
              />
              <Button
                type="button"
                label="Go to profile"
                size="large"
                loading={verifyRequestState.loading}
                fullWidth
                onClick={() => navigate(`/${store.user?.slug}`)}
              />
            </div>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <div className={styles.container}>
          <div className={styles.header}>
            <Card.Header className={styles.header}>
              <div style={{ paddingRight: "1rem" }}>
                Sign in to your Green Flag account
              </div>
            </Card.Header>
          </div>
          <div className={styles.fields}>
            {!!errors.default && (
              <Alert
                className={styles.defaultError}
                title={errors.default}
                intent="danger"
              />
            )}
            {store.credentialId ? (
              <form className={styles.form} onSubmit={verify}>
                <div className={styles.verify}>
                  <FontAwesomeIcon
                    color="var(--color-blue-100)"
                    icon={faEnvelope}
                    style={{ fontSize: "5rem" }}
                  />
                  <div>
                    We sent an email to <strong>{email}</strong>. Please enter
                    the code from that email to finish saving your progress.
                  </div>
                  {!!loginRequestState.error && (
                    <Alert
                      className={styles.defaultError}
                      title={loginRequestState.error.toString()}
                      intent="danger"
                    />
                  )}
                  {!!verifyRequestState.error && (
                    <Alert
                      className={styles.defaultError}
                      title={verifyRequestState.error.toString()}
                      intent="danger"
                    />
                  )}
                  <TextField
                    fullWidth
                    placeholder="Code"
                    conatinerClassName={styles.codeContainer}
                    className={styles.codeField}
                    onChange={(e) => setCode(e.target.value)}
                    size="large"
                    error={errors.code}
                  />
                  <button
                    type="button"
                    className={styles.resend}
                    onClick={resend}
                  >
                    {loginRequestState.loading ? (
                      <Loader color="blue" size={20} />
                    ) : (
                      "Re-send code"
                    )}
                  </button>
                  <button
                    type="button"
                    className={styles.resend}
                    onClick={() => schemaStore.setCredentialId()}
                  >
                    Typed the wrong email?
                  </button>
                  <button
                    type="submit"
                    onClick={() => verify()}
                    style={{ visibility: "hidden" }}
                  >
                    Hidden
                  </button>
                </div>
              </form>
            ) : (
              <form className={styles.form} onSubmit={login}>
                <div>
                  <label>
                    <div className={styles.label}>EMAIL ADDRESS</div>
                    <TextField
                      fullWidth
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      error={errors.email}
                    />
                  </label>
                </div>
              </form>
            )}
          </div>
          <div className={styles.footer}>
            {!store.credentialId ? (
              <Button
                type="button"
                label={"Verify email"}
                size="large"
                loading={loginRequestState.loading}
                fullWidth
                onClick={() => login()}
              />
            ) : (
              <Button
                type="button"
                label={"Sign in"}
                size="large"
                loading={verifyRequestState.loading || redirecting}
                fullWidth
                onClick={() => verify()}
              />
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}
