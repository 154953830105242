import { useOutletContext, useParams } from "react-router-dom";
import { BlogData } from "../../../lib/blogHelpers";
import dayjs from "dayjs";

import styles from "./Blog.module.css";
import Markdown from "react-markdown";
import { NotFound } from "../../../components/NotFound/NotFound";
import { Heading } from "../../../components/Heading/Heading";

export const BlogPost = () => {
  const { data } = useOutletContext<BlogData>();
  const { postSlug } = useParams();
  const post = data.find(
    (d) => d.attributes.slug.toLowerCase() === postSlug?.toLowerCase()
  );
  if (!post) {
    return <NotFound />;
  }
  return (
    <div className={styles.content}>
      <div className={styles.main} key={post.id}>
        <div>
          Posted on {dayjs(post.attributes.date).format("ll")} by{" "}
          {post.attributes.author.data.attributes.name}
        </div>
        <Heading size={2} className={styles.title}>
          {post.attributes.title}
        </Heading>
        <Markdown className={styles.postBody}>{post.attributes.body}</Markdown>
      </div>
    </div>
  );
};
