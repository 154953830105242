export interface NativeEvent<T> {
  type: T;
}

// Incoming Events

export enum IncomingNativeEventType {
  setSafeArea = "setSafeArea",
}

type AbstractIncomingNativeEvent = NativeEvent<IncomingNativeEventType>;

export interface SetSafeAreaEvent extends AbstractIncomingNativeEvent {
  type: IncomingNativeEventType.setSafeArea;
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export type IncomingNativeEvent = SetSafeAreaEvent;

// Outgoing Events

export enum OutgoingNativeEventType {
  Print = "Print",
  GetSafeArea = "GetSafeArea",
}

type AbstractOutgoingNativeEvent = NativeEvent<OutgoingNativeEventType>;

export interface PrintEvent extends AbstractOutgoingNativeEvent {
  type: OutgoingNativeEventType.Print;
  arguments: string[];
}

export interface GetSafeAreaEvent extends AbstractOutgoingNativeEvent {
  type: OutgoingNativeEventType.GetSafeArea;
}

export type OutgoingNativeEvent = PrintEvent | GetSafeAreaEvent;
