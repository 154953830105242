import { Pane, PaneProps } from "evergreen-ui";
import classNames from "classnames";
import { PropsWithChildren } from "react";
import { useGFTheme } from "../../lib/themes";

/*
 * A note on this component:
 * Evergreen supplies an isActive property to their standard button, however their use of :active
 * and :focus pseudo-selectors for styling does not work consistently on mobile. For that reason we
 * had to re-implement the functionality in this special toggle button component.
 */

interface ToggleButtonProps extends PropsWithChildren<PaneProps<"button">> {
  isActive?: boolean;
}

export const ToggleButton = ({
  isActive,
  children,
  ...props
}: ToggleButtonProps) => {
  const { colors } = useGFTheme();
  return (
    <Pane
      is="button"
      height="45px"
      borderWidth="2px"
      borderColor={colors.greenDark}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      cursor="pointer"
      borderRadius="0.3125rem"
      background="white"
      paddingX="1rem"
      position="relative"
      color={colors.greenDark}
      className={classNames(props.className, {
        active: isActive,
      })}
      selectors={{
        "&:hover": {
          backgroundColor: colors.neutralMedium,
        },
        "&.active": {
          backgroundColor: colors.greenLight,

          fontWeight: "bold",
        },
        "& > .bold-spacer": {
          fontWeight: "bold",
          visibility: "hidden",
          height: 0,
          whiteSpace: "nowrap",
        },
        ...props.selectors,
      }}
      {...props}
    >
      <Pane className="text">{children}</Pane>
      <Pane className="bold-spacer">{children}</Pane>
    </Pane>
  );
};
