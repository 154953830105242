import styles from "./Event.module.css";
import { useCallback } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

import classNames from "classnames";
import { apiReq } from "../../../lib/apiReq";

import posthog from "posthog-js";
import { useNavigate, useOutletContext } from "react-router-dom";
import { CMSEventAttributes, CMSItem } from "@greenflagdate/shared";
import { Card } from "../../../components/Card/Card";
import { Heading } from "evergreen-ui";
import { Button } from "../../../components/Button/Button";
import { useReq } from "@larner.dev/use-req";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY!);

export function EventCheckout() {
  const navigate = useNavigate();
  const { event, url } = useOutletContext<{
    event: CMSItem<CMSEventAttributes>;
    url: string;
  }>();
  const [sessionRequest] = useReq<{
    clientSecret: string;
  }>(apiReq);

  const fetchClientSecret = useCallback(async () => {
    const response = await sessionRequest.post("/checkout/session", {
      priceId: event.attributes.priceId!,
    });
    if (!response.success) {
      throw new Error(response.error.code);
    }
    return response.result.clientSecret;
  }, [event.attributes.priceId, sessionRequest]);
  const onStripeComplete = useCallback(() => {
    posthog.capture("eventPayment", {
      eventId: event.id,
    });

    navigate(url + "/success");
  }, [event.id, navigate, url]);
  return (
    <div
      className={classNames(styles.formContainer, styles.gfc)}
      style={{ flex: 1 }}
    >
      <div className={styles.formContent}>
        <Card className={styles.formCard}>
          {event.attributes.womenSpotsLeft || event.attributes.menSpotsLeft ? (
            <>
              <Heading size={2} className={styles.title}>
                Reserve Your Spot!
              </Heading>
              <p style={{ margin: "2rem 0" }}>
                You can request a refund at anytime before you start the
                challenge emailing hi@greenflagdate.com.
              </p>
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={{
                  fetchClientSecret,
                  onComplete: onStripeComplete,
                }}
              >
                <EmbeddedCheckout className={styles.checkoutForm} />
              </EmbeddedCheckoutProvider>
            </>
          ) : (
            <>
              <Heading size={2} className={styles.title}>
                {event.attributes.title} is full
              </Heading>
              <p style={{ margin: "2rem 0" }}>
                We have other events happening that you might be interested in!
              </p>
              <Button
                href="/events"
                label="Check out other events"
                size="large"
                style={{ margin: "0 auto" }}
              />
            </>
          )}
        </Card>
      </div>
    </div>
  );
}
