import {
  CMSDataList,
  CMSItem,
  CMSMeta,
  CMSSparkActivityAttributes,
} from "@greenflagdate/shared";
import { cmsRequest } from "./cmsHelpers";
import { ReqMethod } from "@larner.dev/req";
import { useEffect, useState } from "react";

export interface SparkActivityData
  extends CMSDataList<CMSSparkActivityAttributes> {
  meta: CMSMeta;
}

export const sparkActivityPromise: Promise<SparkActivityData> =
  cmsRequest[ReqMethod.GET]<SparkActivityData>("/api/activities");

let sparkActivityPromiseResolved = false;
export const useSparkActivityData = () => {
  const [state, setState] = useState<{
    loading: boolean;
    sparkActivities: CMSItem<CMSSparkActivityAttributes>[];
  }>({
    loading: !sparkActivityPromiseResolved,
    sparkActivities: [],
  });
  useEffect(() => {
    sparkActivityPromise.then((sparkActivity) => {
      sparkActivityPromiseResolved = true;
      setState({ loading: false, sparkActivities: sparkActivity.data });
    });
  }, []);
  return state;
};
