import { commonGenders } from "@greenflagdate/shared";
import {
  Pane,
  CaretDownIcon,
  Dialog,
  SearchInput,
  Menu,
  Button,
  Group,
} from "evergreen-ui";
import { VerticalGroup } from "../VerticalGroup/VerticalGroup";
import { useCallback, useState } from "react";
import { ToggleButton } from "../ToggleButton/ToggleButton";

interface GenderSelectorProps {
  value?: string;
  onChange: (value?: string) => void;
  appearance?: "vertical" | "horizontal";
}

export const GenderSelector = ({
  value,
  onChange,
  appearance,
}: GenderSelectorProps) => {
  const [moreModalVisible, setMoreModalVisible] = useState(false);
  const [genderSearch, setGenderSearch] = useState("");
  const select = useCallback(
    (gender?: string) => () => {
      onChange(gender);
      setMoreModalVisible(false);
    },
    [onChange]
  );
  appearance = appearance || "vertical";
  const WrapperComponent = appearance === "horizontal" ? Group : VerticalGroup;
  return (
    <>
      <Pane
        display="flex"
        flexDirection="column"
        gap="0.75rem"
        alignItems={appearance === "horizontal" ? "flex-start" : "flex-end"}
      >
        <WrapperComponent width="100%">
          <ToggleButton
            type="button"
            width="100%"
            onClick={select("man")}
            isActive={value?.toLowerCase() === "man"}
          >
            Man
          </ToggleButton>
          <ToggleButton
            type="button"
            width="100%"
            onClick={select("woman")}
            isActive={value?.toLowerCase() === "woman"}
            borderTopRightRadius={
              value && !["man", "woman"].includes(value.toLowerCase())
                ? 0
                : "0.3125rem!important"
            }
            borderBottomRightRadius={
              value && !["man", "woman"].includes(value.toLowerCase())
                ? 0
                : "0.3125rem!important"
            }
          >
            Woman
          </ToggleButton>
          <ToggleButton
            type="button"
            width="100%"
            onClick={select("")}
            isActive={true}
            visibility={
              value && !["man", "woman"].includes(value.toLowerCase())
                ? "visible"
                : "hidden"
            }
            display={
              value && !["man", "woman"].includes(value.toLowerCase())
                ? "block"
                : appearance === "horizontal"
                ? "block"
                : "none"
            }
          >
            {value}
          </ToggleButton>
        </WrapperComponent>
        <Button
          iconAfter={CaretDownIcon}
          appearance="minimal"
          type="button"
          onClick={() => setMoreModalVisible(true)}
        >
          More
        </Button>
      </Pane>
      <Dialog
        isShown={moreModalVisible}
        title="What's your gender?"
        onCloseComplete={() => setMoreModalVisible(false)}
        hasFooter={false}
        containerProps={{
          height: "100%",
        }}
        contentContainerProps={{
          height: "100%",
          selectors: {
            "& > div": {
              height: "100%",
              display: "flex",
              overflow: "hidden",
              flexDirection: "column",
              gap: "0.75rem",
            },
          },
        }}
      >
        <SearchInput
          minHeight="2.875rem"
          alignItems="center"
          width="100%"
          value={genderSearch}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setGenderSearch(e.target.value)
          }
        />
        <Pane flex={1} overflowY="scroll" overflowX="hidden">
          {genderSearch.length > 0 && (
            <Menu>
              <Menu.Item onClick={select(genderSearch)}>
                {genderSearch}
              </Menu.Item>
              {commonGenders
                .filter(
                  (g) =>
                    g.toLowerCase().includes(genderSearch.toLowerCase()) &&
                    g !== genderSearch
                )
                .map((g) => (
                  <Menu.Item onClick={select(g)}>{g}</Menu.Item>
                ))}
            </Menu>
          )}
        </Pane>
      </Dialog>
    </>
  );
};
