import classNames from "classnames";
import { CSSProperties, PropsWithChildren } from "react";
import { PolymorphicBoxProps, HeadingOwnProps } from "evergreen-ui";
import { UIProps } from "../../lib/UIProps";
import styles from "./Heading.module.css";
import { Heading as EvergreenHeading } from "evergreen-ui";

interface HeadingProps {
  className?: string;
  size: 1 | 2 | 3 | 4 | 5 | 6;
  style?: CSSProperties;
}

export function Heading({
  size,
  children,
  className,
  style,
}: PropsWithChildren<HeadingProps>) {
  const props = {
    style,
    className: classNames(styles.heading, className),
  };
  if (size === 1) {
    return <h1 {...props}>{children}</h1>;
  } else if (size === 2) {
    return <h2 {...props}>{children}</h2>;
  } else if (size === 3) {
    return <h3 {...props}>{children}</h3>;
  } else if (size === 4) {
    return <h4 {...props}>{children}</h4>;
  } else if (size === 5) {
    return <h5 {...props}>{children}</h5>;
  } else if (size === 6) {
    return <h6 {...props}>{children}</h6>;
  }
  return null;
}

type HP = UIProps & PolymorphicBoxProps<"h1" | "h2" | "h3", HeadingOwnProps>;

export const H1 = (props: HP) => {
  return <EvergreenHeading size={100} is="h1" {...props} />;
};

export const H2 = (props: HP) => {
  return <EvergreenHeading size={200} is="h2" {...props} />;
};

export const H3 = (props: HP) => {
  return <EvergreenHeading size={300} is="h3" {...props} />;
};
