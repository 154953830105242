import { TextInputFieldOwnProps, FormField, Pane, Select } from "evergreen-ui";
import { useGFTheme } from "../../lib/themes";
import { useMemo } from "react";

interface DateFieldProps extends Omit<TextInputFieldOwnProps, "type"> {
  name?: string;
  value?: string;
  onChange: (value: string) => void;
}

export const DateField = ({ onChange, value, ...props }: DateFieldProps) => {
  const { colors } = useGFTheme();
  const currentYear = new Date().getFullYear();
  const valueString = value || "0000-00-00";

  const year = useMemo(() => valueString.substring(0, 4), [valueString]);
  const month = useMemo(() => valueString.substring(5, 7), [valueString]);
  const day = useMemo(() => valueString.substring(8), [valueString]);

  return (
    <FormField {...props}>
      <Pane
        borderWidth="2px"
        borderColor={colors.greenDark}
        borderStyle="solid"
        borderRadius="5px"
        alignItems="center"
        height="2.875rem"
        display="inline-flex"
        gap="0.5rem"
        paddingX="0.5rem"
        background="#ffffff"
      >
        <Select
          value={month}
          onChange={(e) =>
            onChange(
              valueString.substring(0, 5) +
                e.target.value +
                valueString.substring(7)
            )
          }
          selectors={{
            "& select": { border: 0, backgroundColor: colors.neutralLight },
          }}
          maxWidth="5rem"
        >
          <option value="00">MM</option>
          {Array.from({ length: 12 }, (_e, i) => i + 1).map((month) => {
            const m = month.toString().padStart(2, "0");
            return (
              <option value={m} key={m}>
                {m}
              </option>
            );
          })}
        </Select>
        <Select
          value={day}
          onChange={(e) =>
            onChange(valueString.substring(0, 8) + e.target.value)
          }
          selectors={{
            "& select": { border: 0, backgroundColor: colors.neutralLight },
          }}
          maxWidth="5rem"
        >
          <option value="00">DD</option>
          {Array.from({ length: 31 }, (_e, i) => i + 1).map((day) => {
            const d = day.toString().padStart(2, "0");
            return (
              <option value={d} key={d}>
                {d}
              </option>
            );
          })}
        </Select>
        <Select
          value={year}
          onChange={(e) => onChange(e.target.value + valueString.substring(4))}
          selectors={{
            "& select": { border: 0, backgroundColor: colors.neutralLight },
          }}
          maxWidth="8rem"
          width="8rem"
        >
          <option value="0000">YYYY</option>
          {Array.from({ length: 90 }, (_e, i) => i).map((delta) => {
            const year = (currentYear - delta - 10).toString();
            return (
              <option value={year} key={year}>
                {year}
              </option>
            );
          })}
        </Select>
      </Pane>
    </FormField>
  );
};
