import { useReq } from "@larner.dev/use-req";
import { H1 } from "../../../components/Heading/Heading";
import { Meta } from "../../../components/Meta/Meta";
import { Pane } from "evergreen-ui";
import { cmsRequest } from "../../../lib/cmsHelpers";
import { useEffect } from "react";
import { LoaderScreen } from "../../../components/LoaderScreen/LoaderScreen";
import { CMSDataList, Policy as PolicyAttributes } from "@greenflagdate/shared";
import { Link } from "../../../components/Link/Link";

export function Legal() {
  const [request, requestState] =
    useReq<CMSDataList<PolicyAttributes>>(cmsRequest);
  useEffect(() => {
    request.get("/api/policies");
  }, [request]);

  if (requestState.loading) {
    return <LoaderScreen />;
  }

  const policies = requestState.result?.data || [];

  return (
    <Pane marginX="auto" maxWidth="32rem" paddingX="2rem" paddingBottom="2rem">
      <Meta>
        <title>Legal - Green Flag</title>
      </Meta>
      <H1 textAlign="center" paddingY="2rem">
        Legal
      </H1>
      <Pane is="ul">
        {policies.map((p) => (
          <Pane is="li">
            <Link to={`/policy/${p.attributes.slug}`}>
              {p.attributes.title}
            </Link>
          </Pane>
        ))}
      </Pane>
    </Pane>
  );
}
