import { Link } from "react-router-dom";
import { Button } from "../../../components/Button/Button";
import { Card } from "../../../components/Card/Card";
import { Heading } from "../../../components/Heading/Heading";
import styles from "./Newsletter.module.css";
export const NewsletterSuccess = () => {
  return (
    <div className={styles.formContainer}>
      <div className={styles.formContent}>
        <Card className={styles.formCard}>
          <Heading size={2} className={styles.title}>
            You&apos;ve subscribed to the Green Flag newsletter!
          </Heading>
          <p>
            You&apos;ll get future green flag newsletters. If you want to read
            previous content you can check out our{" "}
            <Link to="/blog" className={styles.blogLink}>
              blog
            </Link>
            .
          </p>
          <p>Checkout out the green flag course to</p>
          <Button
            size="large"
            label="Discover Your Green Flags"
            href="/discover/stability"
            style={{ margin: "2rem auto 0 auto" }}
          />
        </Card>
      </div>
    </div>
  );
};
