import { VerticalGroup } from "../VerticalGroup/VerticalGroup";
import { ToggleButton } from "../ToggleButton/ToggleButton";
import { GenderPreference } from "@greenflagdate/shared";
import { Group } from "evergreen-ui";

interface GenderPreferenceProps {
  value?: string;
  onChange: (value?: GenderPreference) => void;
  appearance?: "vertical" | "horizontal";
}

export const GenderPreferenceSelector = ({
  value,
  onChange,
  appearance,
}: GenderPreferenceProps) => {
  appearance = appearance || "vertical";
  const WrapperComponent = appearance === "horizontal" ? Group : VerticalGroup;

  return (
    <WrapperComponent width="100%">
      <ToggleButton
        width="100%"
        onClick={() => onChange("man")}
        isActive={value?.toLowerCase() === "man"}
      >
        Men
      </ToggleButton>
      <ToggleButton
        width="100%"
        onClick={() => onChange("woman")}
        isActive={value?.toLowerCase() === "woman"}
      >
        Women
      </ToggleButton>
      <ToggleButton
        width="100%"
        onClick={() => onChange("everyone")}
        isActive={value?.toLowerCase() === "everyone"}
      >
        Everyone
      </ToggleButton>
    </WrapperComponent>
  );
};
