import { Theme, defaultTheme, mergeTheme, useTheme } from "evergreen-ui";

// @ts-expect-error focusRing
const lightShadows: string[] & { focusRing: string } = [
  "0px 4px 4px 0px #00000040",
];
lightShadows.focusRing = `0 0 0 2px #39B87D`;

export interface GFTheme extends Theme {
  colors: {
    neutralDark: string;
    neutralLight: string;
    neutralMedium: string;
    greenLight: string;
    greenPrimary: string;
    greenDark: string;
    blueLight: string;
    bluePrimary: string;
    blueDark: string;
    orangePrimary: string;
    orangeDark: string;
    redPrimary: string;
    redDark: string;
    grayPrimary: string;
  };
}

export const useGFTheme = useTheme<GFTheme>;

export const lightTheme = mergeTheme(defaultTheme, {
  colors: {
    neutralDark: "#000000",
    neutralMedium: "#E0E0E0",
    neutralLight: "#F5F1EF",
    greenLight: "#39B87D",
    greenPrimary: "#118053",
    greenDark: "#1A3224",
    blueLight: "#4CAFF0",
    bluePrimary: "#0B80CD",
    blueDark: "#083653",
    grayPrimary: "#A5A5A5",
    orangePrimary: "#EF863F",
    orangeDark: "#3F2616",
    redPrimary: "#EC5F5F",
    redDark: "#5B1414",
  },
  fills: {},
  intents: {},
  fontFamilies: {
    display: "league-gothic",
    ui: "work-sans",
    mono: "Courier New",
  },
  radii: [],
  shadows: lightShadows,
  fontSizes: ["0.75rem", "1rem", "1.25rem", "1.5rem", "3.125rem"],
  fontWeights: {
    light: 300,
    normal: 400,
    semibold: 500,
    bold: 700,
  },
  letterSpacings: {
    tightest: "-0.2px",
    tighter: "-0.07px",
    tight: "-0.05px",
    normal: "0",
    wide: "0.6px",
  },
  lineHeights: [],
  zIndices: {
    focused: 2,
    stack: 5,
    positioner: 10,
    overlay: 20,
    toaster: 30,
  },
  components: {
    DialogHeader: {
      baseStyle: {
        selectors: {
          "& h4": {
            fontSize: "3.125rem",
            fontFamily: "league-gothic",
            lineHeight: "3.125rem",
            color: "colors.greenDark",
          },
        },
      },
    },
    Heading: {
      baseStyle: {
        color: "colors.greenDark",
        fontFamily: "work-sans",
        lineHeight: "1.2",
        letterSpacing: "-0.02em",
        textAlign: "left",
        fontWeight: "fontWeights.normal",
        position: "relative",
        zIndex: "0",
      },
      sizes: {
        "100": {
          fontSize: "3.125rem",
          fontFamily: "league-gothic",
          lineHeight: "3.125rem",
        },
        "200": {
          fontSize: "1.5rem",
          fontWeight: "fontWeights.bold",
          lineHeight: "1.8rem",
          color: "colors.greenDark",
        },
        "300": {
          fontSize: "1.25rem",
          fontWeight: "fontWeights.semibold",
          lineHeight: "1.5rem",
          color: "colors.greenDark",
        },
      },
    },
    Paragraph: {
      baseStyle: {
        lineHeight: "1.6",
        fontWeight: "fontWeights.normal",
        fontFamily: "fontFamilies.ui",
      },
      sizes: {
        "300": {
          fontSize: "fontSizes.0",
          color: "colors.greenDark",
        },
        "400": {
          fontSize: "fontSizes.0",
          fontWeight: "fontWeights.bold",
          color: "colors.greenDark",
        },
        "500": {
          fontSize: "fontSizes.1",
          color: "colors.greenDark",
        },
        small: {
          fontSize: "fontSizes.0",
          color: "colors.greenDark",
        },
        medium: {
          fontSize: "fontSizes.0",
          fontWeight: "fontWeights.bold",
          color: "colors.greenDark",
        },
        large: {
          fontSize: "fontSizes.1",
          color: "colors.greenDark",
        },
      },
    },
    Button: {
      baseStyle: {
        fontFamily: "work-sans",
        borderRadius: "6.25rem",
        fontSize: "1rem",
        fontWeight: "700",
        lineHeight: "1rem",
        textAlign: "center",
        textTransform: "uppercase",
      },
      appearances: {
        default: {
          backgroundColor: "white",
          border: "2px solid",
          borderColor: "colors.greenDark",
          color: "colors.greenDark",
          boxSizing: "border-box",
          _focus: {
            boxShadow: "shadows.focusRing",
          },
          _disabled: {
            cursor: "not-allowed",
            pointerEvents: "none",
            opacity: "0.5",
            color: "colors.greenDark",
          },

          selectors: {
            _disabled: {
              opacity: "0.5",
              color: "colors.greenDark",
            },
            _hover: {
              backgroundColor: "colors.greenDark",
              color: "colors.neutralLight",
            },
            _active: {
              backgroundColor: "colors.gray100",
            },
          },
        },
        primary: {
          backgroundColor: "colors.neutralDark",
          border: "none",
          color: "colors.neutralLight",
          boxSizing: "border-box",
          _focus: {
            boxShadow: "shadows.focusRing",
          },
          _disabled: {
            cursor: "not-allowed",
            pointerEvents: "none",
            opacity: "0.25",
            color: "colors.neutralLight",
          },
          selectors: {
            _disabled: {
              opacity: "0.25",
              color: "colors.neutralLight",
            },
            _hover: {
              backgroundColor: "colors.greenDark",
              color: "colors.neutralLight",
              border: "none",
            },
            _active: {
              backgroundColor: "colors.darkGreen",
            },
          },
        },
        minimal: {
          backgroundColor: "colors.neutralLight",
          border: "none",
          color: "colors.blueDark",
          boxSizing: "border-box",
          _focus: {
            boxShadow: "shadows.focusRing",
          },
          _disabled: {
            cursor: "not-allowed",
            pointerEvents: "none",
            opacity: "0.25",
            color: "colors.blueDark",
          },
          selectors: {
            _disabled: {
              opacity: "0.25",
              color: "colors.blueDark",
            },
            _hover: {
              backgroundColor: "colors.blueDark",
              color: "colors.neutralLight",
              border: "none",
            },
            _active: {
              backgroundColor: "colors.gray100",
            },
          },
        },
      },
      sizes: {
        small: {
          height: 32,
          minWidth: 24,
          fontSize: "fontSizes.0",
          lineHeight: "24px",
          paddingLeft: 16,
          paddingRight: 16,
        },
        medium: {
          height: 45,
          minWidth: 32,
          fontSize: "fontSizes.1",
          lineHeight: "45px",
          paddingLeft: 30,
          paddingRight: 30,
        },
      },
    },
    Input: {
      baseStyle: {
        selectors: {
          "&[type='text'],&[type='email'],&[type='search']": {
            fontSize: "1rem",
            height: "2.875rem",
          },
        },
      },
      appearances: {
        default: {
          className: "base",
          borderWidth: "2px",
          borderColor: "colors.greenDark",
          borderStyle: "solid",
          borderRadius: "5px",
          selectors: {
            "&:focus": {
              boxShadow: "0",
              borderColor: "colors.greenLight",
              height: "2.875rem",
            },
          },
        },
      },
    },
  },
} as GFTheme);
