import classNames from "classnames";
import { CSSProperties } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./Alert.module.css";
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";

interface AlertProps {
  title: string;
  intent?: "none" | "success" | "danger";
  subtitle?: string;
  style?: CSSProperties;
  className?: string;
}

export function Alert({
  intent,
  title,
  subtitle,
  style,
  className,
}: AlertProps) {
  intent = intent || "none";
  let icon;
  let containerStyle;
  if (intent === "none") {
    icon = <FontAwesomeIcon icon={faCircleInfo} />;
    containerStyle = styles.none;
  } else if (intent === "success") {
    icon = <FontAwesomeIcon icon={faCircleCheck} />;
    containerStyle = styles.success;
  } else if (intent === "danger") {
    icon = <FontAwesomeIcon icon={faCircleExclamation} />;
    containerStyle = styles.danger;
  }
  return (
    <div
      className={classNames(styles.container, containerStyle, className)}
      style={style}
    >
      {icon}
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        {!!subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </div>
    </div>
  );
}
