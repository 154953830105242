import { useReq } from "@larner.dev/use-req";
import { H1, H2, H3 } from "../../../components/Heading/Heading";
import { Meta } from "../../../components/Meta/Meta";
import { Pane, Paragraph } from "evergreen-ui";
import { cmsRequest } from "../../../lib/cmsHelpers";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { LoaderScreen } from "../../../components/LoaderScreen/LoaderScreen";
import { CMSDataList, Policy as PolicyAttributes } from "@greenflagdate/shared";
import { NotFound } from "../../../components/NotFound/NotFound";
import Markdown from "react-markdown";
import { useGFTheme } from "../../../lib/themes";
import { Link } from "../../../components/Link/Link";
import dayjs from "dayjs";
import ReactDOMServer from "react-dom/server";

const getHeaderId = (element: React.ReactNode): string => {
  const htmlString = ReactDOMServer.renderToStaticMarkup(element);
  // Strip HTML tags using regex
  const strippedString = htmlString
    .replace(/<\/?[^>]+(>|$)/g, "")
    .replace(/ /g, "-");
  return strippedString.toLowerCase();
};

export function Policy() {
  const { slug } = useParams();
  const { colors } = useGFTheme();
  const [request, requestState] =
    useReq<CMSDataList<PolicyAttributes>>(cmsRequest);
  useEffect(() => {
    request.get(`/api/policies?filters[slug][$eq]=${slug}`);
  }, [slug, request]);

  if (requestState.loading) {
    return <LoaderScreen />;
  }
  if (requestState.error || !requestState.result?.data.length) {
    return <NotFound />;
  }

  const [policy] = requestState.result.data;

  return (
    <Pane marginX="auto" maxWidth="32rem" paddingX="2rem" paddingBottom="2rem">
      <Meta>
        <title>{policy.attributes.title} - Green Flag</title>
      </Meta>
      <H1 textAlign="center" paddingY="2rem">
        {policy.attributes.title}
      </H1>
      <Paragraph size={300}>
        Last Updated {dayjs(policy.attributes.createdAt).format("LL")}
      </Paragraph>
      <Markdown
        components={{
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          h1({ is, ref, ...props }) {
            if (!props.id && props.children) {
              props.id = getHeaderId(props.children);
            }
            return <H1 {...props} />;
          },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          h2({ is, ref, ...props }) {
            if (!props.id && props.children) {
              props.id = getHeaderId(props.children);
            }
            return <H2 marginY="1rem" {...props} />;
          },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          h3({ is, ref, ...props }) {
            if (!props.id && props.children) {
              props.id = getHeaderId(props.children);
            }
            return <H3 marginY="1rem" {...props} />;
          },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          p({ is, ref, ...props }) {
            return (
              <Paragraph
                size={500}
                color={colors.greenDark}
                marginBottom="1rem"
                {...props}
              />
            );
          },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          a({ is, ref, href, ...props }) {
            const to = href || "";
            return (
              <Link
                to={to}
                {...props}
                color={colors.greenDark}
                selectors={{ "&:hover": { color: colors.greenPrimary } }}
                component={to.startsWith("#") ? "hash-link" : "link"}
              />
            );
          },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ul({ is, ref, ...props }) {
            return (
              <Pane
                is="ul"
                lineHeight="1.5rem"
                paddingLeft="2rem"
                marginBottom="1rem"
                {...props}
              />
            );
          },
        }}
      >
        {policy.attributes.content}
      </Markdown>
    </Pane>
  );
}
