import { BoxComponent, Pane, PaneOwnProps } from "evergreen-ui";
// import { useGFTheme } from "../../lib/themes";

export const VerticalGroup: BoxComponent<PaneOwnProps, "div"> = (props) => {
  // const { colors } = useGFTheme();
  return (
    <Pane
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      gap="0.75rem"
      {...props}
      // selectors={{
      //   "& > button": {
      //     borderRadius: "0.3125rem",
      //     textTransform: "none",
      //   },
      //   "& > button:not([disabled]):hover": {
      //     backgroundColor: colors.greenLight,
      //     border: "2px solid",
      //     borderColor: colors.greenDark,
      //   },
      //   "& > button:not([disabled])[data-active]": {
      //     backgroundColor: colors.greenLight,
      //     color: colors.neutralLight,
      //   },
      //   "& > button:not([disabled]):focus": {
      //     boxShadow: "none",
      //   },
      //   "& > button:not([disabled]):hover:focus": {
      //     backgroundColor: "inherit",
      //     color: "inherit",
      //   },
      //   "& > button:not([disabled]):hover:focus[data-active]": {
      //     backgroundColor: colors.greenLight,
      //     color: colors.neutralLight,
      //   },

      //   "& > button:not([disabled]):hover:active": {
      //     backgroundColor: colors.greenDark,
      //     color: colors.neutralLight,
      //   },

      //   ...(props?.selectors || {}),
      // }}
    />
  );
};
