import { ThemeProvider } from "evergreen-ui";
import { PropsWithChildren } from "react";
import { useGFTheme, lightTheme } from "../../lib/themes";
import { Meta } from "../Meta/Meta";

const GFThemeContent = ({ children }: PropsWithChildren) => {
  const { colors } = useGFTheme();
  return (
    <>
      <Meta>
        <style>
          {`
              :root {
                ${Object.entries(colors)
                  .map(([key, value]) => `--${key}: ${value};`)
                  .join("\n")}
              }
              html, body {
                background-color: ${colors.neutralLight}
              }
              div.react-date-picker__wrapper {
                border: 0;
              }
              
              input.react-date-picker__inputGroup__input:invalid {
                background-color: var(--color-white);
              }
              
              button.react-calendar__tile--now:enabled {
                background: var(--color-primary-300);
              }
              
              button.react-calendar__tile--now:enabled:hover {
                background: var(--color-primary-400);
              }
              
              button.react-calendar__month-view__days__day--weekend {
                color: var(--color-dark);
              }
              h1 u,
              h2 u,
              h3 u {
                text-decoration: none;
                display: inline-block;
              }
  
              h1 u:after, h2 u:after, h3 u:after {
                display: block;
                width: 100%;
                height: 0.2em;
                position: relative;
                background: ${colors.greenLight};
                content: " ";
                z-index: -1;
                top: -0.25em;
              }
              h1 u:after {
                top: -8px;
              }
              h1 {
                font-variation-settings: "wdth" 90;
              }
              li {
                color: ${colors.greenDark};
              }
            `}
        </style>
      </Meta>
      {children}
    </>
  );
};

export const GFThemeProvider = ({ children }: PropsWithChildren) => {
  return (
    <ThemeProvider value={lightTheme}>
      <GFThemeContent>{children}</GFThemeContent>
    </ThemeProvider>
  );
};
