// Enum for the response codes
export enum ANETCode {
  I_WC_01 = "I_WC_01",
  E_WC_01 = "E_WC_01",
  E_WC_02 = "E_WC_02",
  E_WC_03 = "E_WC_03",
  E_WC_04 = "E_WC_04",
  E_WC_05 = "E_WC_05",
  E_WC_06 = "E_WC_06",
  E_WC_07 = "E_WC_07",
  E_WC_08 = "E_WC_08",
  E_WC_10 = "E_WC_10",
  E_WC_13 = "E_WC_13",
  E_WC_14 = "E_WC_14",
  E_WC_15 = "E_WC_15",
  E_WC_16 = "E_WC_16",
  E_WC_17 = "E_WC_17",
  E_WC_18 = "E_WC_18",
  E_WC_19 = "E_WC_19",
  E_WC_20 = "E_WC_20",
  E_WC_21 = "E_WC_21",
  E_WC_23 = "E_WC_23",
  E_WC_24 = "E_WC_24",
  E_WC_25 = "E_WC_25",
  E_WC_26 = "E_WC_26",
  E_WC_27 = "E_WC_27",
  E_I00001 = "I00001",
  E_I00002 = "I00002",
  E_I00003 = "I00003",
  E_I00004 = "I00004",
  E_I00005 = "I00005",
  E_I00006 = "I00006",
  E_I00007 = "I00007",
  E_I00008 = "I00008",
  E_I00009 = "I00009",
  E_I00010 = "I00010",
  E_I00011 = "I00011",
  E_I99999 = "I99999",
  E_E00001 = "E00001",
  E_E00002 = "E00002",
  E_E00003 = "E00003",
  E_E00004 = "E00004",
  E_E00005 = "E00005",
  E_E00006 = "E00006",
  E_E00007 = "E00007",
  E_E00008 = "E00008",
  E_E00009 = "E00009",
  E_E00010 = "E00010",
  E_E00011 = "E00011",
  E_E00012 = "E00012",
  E_E00013 = "E00013",
  E_E00014 = "E00014",
  E_E00015 = "E00015",
  E_E00016 = "E00016",
  E_E00017 = "E00017",
  E_E00018 = "E00018",
  E_E00019 = "E00019",
  E_E00020 = "E00020",
  E_E00021 = "E00021",
  E_E00022 = "E00022",
  E_E00023 = "E00023",
  E_E00024 = "E00024",
  E_E00025 = "E00025",
  E_E00026 = "E00026",
  E_E00027 = "E00027",
  E_E00028 = "E00028",
  E_E00029 = "E00029",
  E_E00030 = "E00030",
  E_E00031 = "E00031",
  E_E00032 = "E00032",
  E_E00033 = "E00033",
  E_E00034 = "E00034",
  E_E00035 = "E00035",
  E_E00036 = "E00036",
  E_E00037 = "E00037",
  E_E00038 = "E00038",
  E_E00039 = "E00039",
  E_E00040 = "E00040",
  E_E00041 = "E00041",
  E_E00042 = "E00042",
  E_E00043 = "E00043",
  E_E00044 = "E00044",
  E_E00045 = "E00045",
  E_E00046 = "E00046",
  E_E00047 = "E00047",
  E_E00048 = "E00048",
  E_E00049 = "E00049",
  E_E00050 = "E00050",
  E_E00051 = "E00051",
  E_E00052 = "E00052",
  E_E00053 = "E00053",
  E_E00054 = "E00054",
  E_E00055 = "E00055",
  E_E00056 = "E00056",
  E_E00057 = "E00057",
  E_E00058 = "E00058",
  E_E00059 = "E00059",
  E_E00060 = "E00060",
  E_E00061 = "E00061",
  E_E00062 = "E00062",
  E_E00063 = "E00063",
  E_E00064 = "E00064",
  E_E00065 = "E00065",
  E_E00066 = "E00066",
  E_E00067 = "E00067",
  E_E00068 = "E00068",
  E_E00069 = "E00069",
  E_E00070 = "E00070",
  E_E00071 = "E00071",
  E_E00072 = "E00072",
  E_E00073 = "E00073",
  E_E00074 = "E00074",
  E_E00075 = "E00075",
  E_E00076 = "E00076",
  E_E00077 = "E00077",
  E_E00078 = "E00078",
  E_E00079 = "E00079",
  E_E00080 = "E00080",
  E_E00081 = "E00081",
  E_E00082 = "E00082",
  E_E00083 = "E00083",
  E_E00084 = "E00084",
  E_E00085 = "E00085",
  E_E00086 = "E00086",
  E_E00087 = "E00087",
  E_E00088 = "E00088",
  E_E00089 = "E00089",
  E_E00090 = "E00090",
  E_E00091 = "E00091",
  E_E00092 = "E00092",
  E_E00093 = "E00093",
  E_E00094 = "E00094",
  E_E00095 = "E00095",
  E_E00096 = "E00096",
  E_E00097 = "E00097",
  E_E00098 = "E00098",
  E_E00099 = "E00099",
  E_E00100 = "E00100",
  E_E00101 = "E00101",
  E_E00102 = "E00102",
  E_E00103 = "E00103",
  E_E00104 = "E00104",
  E_E00105 = "E00105",
  E_E00106 = "E00106",
  E_E00107 = "E00107",
  E_E00108 = "E00108",
  E_E00109 = "E00109",
  E_E00110 = "E00110",
  E_E00111 = "E00111",
  E_E00112 = "E00112",
  E_E00113 = "E00113",
  E_E00114 = "E00114",
  E_E00115 = "E00115",
  E_E00116 = "E00116",
  E_E00117 = "E00117",
  E_E00118 = "E00118",
  E_E00119 = "E00119",
  E_E00120 = "E00120",
  E_E00121 = "E00121",
  E_E00122 = "E00122",
  E_E00123 = "E00123",
  E_E00124 = "E00124",
  E_E00125 = "E00125",
  E_E00126 = "E00126",
  E_E00127 = "E00127",
  E_E00128 = "E00128",
  E_E00129 = "E00129",
  E_E00130 = "E00130",
  E_E00131 = "E00131",
  E_E00132 = "E00132",
  E_E00133 = "E00133",
  E_E00134 = "E00134",
  E_E00135 = "E00135",
  E_E00136 = "E00136",
  E_E00137 = "E00137",
  E_E00138 = "E00138",
  E_E00139 = "E00139",
  E_E00140 = "E00140",
  E_E00141 = "E00141",
  E_E00142 = "E00142",
  E_E00143 = "E00143",
  E_E00144 = "E00144",
  E_E00145 = "E00145",
  E_0 = "0",
  E_1 = "1",
  E_2 = "2",
  E_3 = "3",
  E_4 = "4",
  E_5 = "5",
  E_6 = "6",
  E_7 = "7",
  E_8 = "8",
  E_9 = "9",
  E_10 = "10",
  E_11 = "11",
  E_12 = "12",
  E_13 = "13",
  E_14 = "14",
  E_15 = "15",
  E_16 = "16",
  E_17 = "17",
  E_18 = "18",
  E_19 = "19",
  E_20 = "20",
  E_21 = "21",
  E_22 = "22",
  E_23 = "23",
  E_24 = "24",
  E_25 = "25",
  E_26 = "26",
  E_27 = "27",
  E_28 = "28",
  E_29 = "29",
  E_30 = "30",
  E_31 = "31",
  E_32 = "32",
  E_33 = "33",
  E_34 = "34",
  E_35 = "35",
  E_36 = "36",
  E_37 = "37",
  E_38 = "38",
  E_39 = "39",
  E_40 = "40",
  E_41 = "41",
  E_42 = "42",
  E_43 = "43",
  E_44 = "44",
  E_45 = "45",
  E_46 = "46",
  E_47 = "47",
  E_48 = "48",
  E_49 = "49",
  E_50 = "50",
  E_51 = "51",
  E_52 = "52",
  E_53 = "53",
  E_54 = "54",
  E_55 = "55",
  E_56 = "56",
  E_57 = "57",
  E_58 = "58",
  E_59 = "59",
  E_60 = "60",
  E_61 = "61",
  E_62 = "62",
  E_63 = "63",
  E_64 = "64",
  E_65 = "65",
  E_66 = "66",
  E_67 = "67",
  E_68 = "68",
  E_69 = "69",
  E_70 = "70",
  E_71 = "71",
  E_72 = "72",
  E_73 = "73",
  E_74 = "74",
  E_75 = "75",
  E_76 = "76",
  E_77 = "77",
  E_78 = "78",
  E_79 = "79",
  E_80 = "80",
  E_81 = "81",
  E_82 = "82",
  E_83 = "83",
  E_84 = "84",
  E_85 = "85",
  E_86 = "86",
  E_87 = "87",
  E_88 = "88",
  E_89 = "89",
  E_90 = "90",
  E_91 = "91",
  E_92 = "92",
  E_93 = "93",
  E_94 = "94",
  E_95 = "95",
  E_96 = "96",
  E_97 = "97",
  E_98 = "98",
  E_99 = "99",
  E_100 = "100",
  E_101 = "101",
  E_102 = "102",
  E_103 = "103",
  E_104 = "104",
  E_105 = "105",
  E_106 = "106",
  E_107 = "107",
  E_108 = "108",
  E_109 = "109",
  E_110 = "110",
  E_111 = "111",
  E_112 = "112",
  E_113 = "113",
  E_114 = "114",
  E_115 = "115",
  E_116 = "116",
  E_117 = "117",
  E_118 = "118",
  E_119 = "119",
  E_120 = "120",
  E_121 = "121",
  E_122 = "122",
  E_123 = "123",
  E_124 = "124",
  E_125 = "125",
  E_126 = "126",
  E_127 = "127",
  E_128 = "128",
  E_130 = "130",
  E_131 = "131",
  E_132 = "132",
  E_141 = "141",
  E_145 = "145",
  E_152 = "152",
  E_153 = "153",
  E_154 = "154",
  E_155 = "155",
  E_156 = "156",
  E_165 = "165",
  E_170 = "170",
  E_171 = "171",
  E_172 = "172",
  E_173 = "173",
  E_174 = "174",
  E_175 = "175",
  E_180 = "180",
  E_181 = "181",
  E_182 = "182",
  E_183 = "183",
  E_184 = "184",
  E_185 = "185",
  E_186 = "186",
  E_187 = "187",
  E_191 = "191",
  E_192 = "192",
  E_193 = "193",
  E_195 = "195",
  E_200 = "200",
  E_201 = "201",
  E_202 = "202",
  E_203 = "203",
  E_204 = "204",
  E_205 = "205",
  E_206 = "206",
  E_207 = "207",
  E_208 = "208",
  E_209 = "209",
  E_210 = "210",
  E_211 = "211",
  E_212 = "212",
  E_213 = "213",
  E_214 = "214",
  E_215 = "215",
  E_216 = "216",
  E_217 = "217",
  E_218 = "218",
  E_219 = "219",
  E_220 = "220",
  E_221 = "221",
  E_222 = "222",
  E_223 = "223",
  E_224 = "224",
  E_225 = "225",
  E_226 = "226",
  E_227 = "227",
  E_228 = "228",
  E_229 = "229",
  E_230 = "230",
  E_231 = "231",
  E_232 = "232",
  E_233 = "233",
  E_234 = "234",
  E_235 = "235",
  E_236 = "236",
  E_237 = "237",
  E_238 = "238",
  E_239 = "239",
  E_240 = "240",
  E_241 = "241",
  E_242 = "242",
  E_243 = "243",
  E_244 = "244",
  E_245 = "245",
  E_246 = "246",
  E_247 = "247",
  E_248 = "248",
  E_250 = "250",
  E_251 = "251",
  E_252 = "252",
  E_253 = "253",
  E_254 = "254",
  E_260 = "260",
  E_261 = "261",
  E_262 = "262",
  E_265 = "265",
  E_270 = "270",
  E_271 = "271",
  E_280 = "280",
  E_281 = "281",
  E_282 = "282",
  E_283 = "283",
  E_284 = "284",
  E_285 = "285",
  E_286 = "286",
  E_287 = "287",
  E_288 = "288",
  E_289 = "289",
  E_290 = "290",
  E_295 = "295",
  E_296 = "296",
  E_297 = "297",
  E_298 = "298",
  E_300 = "300",
  E_301 = "301",
  E_302 = "302",
  E_303 = "303",
  E_304 = "304",
  E_305 = "305",
  E_306 = "306",
  E_307 = "307",
  E_308 = "308",
  E_309 = "309",
  E_310 = "310",
  E_311 = "311",
  E_312 = "312",
  E_313 = "313",
  E_314 = "314",
  E_315 = "315",
  E_316 = "316",
  E_317 = "317",
  E_318 = "318",
  E_319 = "319",
  E_320 = "320",
  E_325 = "325",
  E_326 = "326",
  E_327 = "327",
  E_328 = "328",
  E_330 = "330",
  E_331 = "331",
  E_332 = "332",
  E_333 = "333",
  E_334 = "334",
  E_339 = "339",
  E_340 = "340",
  E_341 = "341",
  E_342 = "342",
  E_343 = "343",
  E_344 = "344",
  E_350 = "350",
  E_351 = "351",
  E_355 = "355",
  E_356 = "356",
  E_357 = "357",
  E_358 = "358",
  E_359 = "359",
  E_360 = "360",
  E_361 = "361",
  E_362 = "362",
  E_363 = "363",
  E_370 = "370",
  E_371 = "371",
  E_375 = "375",
  E_380 = "380",
  E_901 = "901",
  E_2000 = "2000",
  E_2001 = "2001",
  E_2002 = "2002",
  E_2003 = "2003",
  E_2004 = "2004",
  E_2005 = "2005",
  E_2006 = "2006",
  E_2007 = "2007",
  E_2008 = "2008",
  E_2009 = "2009",
  E_2100 = "2100",
  E_2101 = "2101",
  E_2102 = "2102",
  E_2103 = "2103",
  E_2104 = "2104",
  E_2105 = "2105",
  E_2106 = "2106",
  E_2107 = "2107",
  E_2108 = "2108",
  E_2109 = "2109",
  E_2200 = "2200",
}

// Object mapping the enum to the response text
export const ANETErrorMap: { [key in ANETCode]: string } = {
  [ANETCode.I_WC_01]: "Successful.",
  [ANETCode.E_WC_01]: "Please include Accept.js library from CDN.",
  [ANETCode.E_WC_02]: "A HTTPS connection is required.",
  [ANETCode.E_WC_03]: "Accept.js is not loaded correctly.",
  [ANETCode.E_WC_04]: "Please provide mandatory field to library.",
  [ANETCode.E_WC_05]: "Please provide valid credit card number.",
  [ANETCode.E_WC_06]: "Please provide valid expiration month.",
  [ANETCode.E_WC_07]: "Please provide valid expiration year.",
  [ANETCode.E_WC_08]: "The expiration date must be in the future.",
  [ANETCode.E_WC_10]: "Please provide valid apiLoginID.",
  [ANETCode.E_WC_13]: "Invalid Fingerprint.",
  [ANETCode.E_WC_14]: "Accept.js encryption failed.",
  [ANETCode.E_WC_15]: "Please provide valid CVV.",
  [ANETCode.E_WC_16]: "Please provide valid ZIP code.",
  [ANETCode.E_WC_17]: "Please provide valid card holder name.",
  [ANETCode.E_WC_18]: "Client Key is required.",
  [ANETCode.E_WC_19]: "An error occurred during processing. Please try again.",
  [ANETCode.E_WC_20]: "The credit card number is too long.",
  [ANETCode.E_WC_21]:
    "User authentication failed due to invalid authentication values.",
  [ANETCode.E_WC_23]:
    "Please provide either card information or bank information.",
  [ANETCode.E_WC_24]: "Please provide valid account number.",
  [ANETCode.E_WC_25]: "Please provide valid routing number.",
  [ANETCode.E_WC_26]: "Please provide valid account holder name.",
  [ANETCode.E_WC_27]: "Please provide valid account type.",
  [ANETCode.E_I00001]: "Successful.",
  [ANETCode.E_I00002]: "The subscription has already been canceled.",
  [ANETCode.E_I00003]: "The record has already been deleted.",
  [ANETCode.E_I00004]: "No records found.",
  [ANETCode.E_I00005]:
    "The mobile device has been submitted for approval by the account administrator.",
  [ANETCode.E_I00006]: "The mobile device is approved and ready for use.",
  [ANETCode.E_I00007]:
    "The Payment Gateway Account service (id&#x3D;8) has already been accepted.",
  [ANETCode.E_I00008]:
    "The Payment Gateway Account service (id&#x3D;8) has already been declined.",
  [ANETCode.E_I00009]: "The APIUser already exists.",
  [ANETCode.E_I00010]: "The merchant is activated successfully.",
  [ANETCode.E_I00011]: "The merchant is not activated.",
  [ANETCode.E_I99999]:
    "This feature has not yet been completed. One day it will be but it looks like today is not that day.",
  [ANETCode.E_E00001]: "An error occurred during processing. Please try again.",
  [ANETCode.E_E00002]: "The content-type specified is not supported.",
  [ANETCode.E_E00003]: "An error occurred while parsing the XML request.",
  [ANETCode.E_E00004]: "The name of the requested API method is invalid.",
  [ANETCode.E_E00005]:
    "The transaction key or API key is invalid or not present.",
  [ANETCode.E_E00006]: "The API user name is invalid or not present.",
  [ANETCode.E_E00007]:
    "User authentication failed due to invalid authentication values.",
  [ANETCode.E_E00008]:
    "User authentication failed. The account or API user is inactive.",
  [ANETCode.E_E00009]:
    "The payment gateway account is in Test Mode. The request cannot be processed.",
  [ANETCode.E_E00010]:
    "User authentication failed. You do not have the appropriate permissions.",
  [ANETCode.E_E00011]:
    "Access denied. You do not have the appropriate permissions.",
  [ANETCode.E_E00012]: "A duplicate subscription already exists.",
  [ANETCode.E_E00013]: "The field is invalid.",
  [ANETCode.E_E00014]: "A required field is not present.",
  [ANETCode.E_E00015]: "The field length is invalid.",
  [ANETCode.E_E00016]: "The field type is invalid.",
  [ANETCode.E_E00017]: "The start date cannot occur in the past.",
  [ANETCode.E_E00018]:
    "The credit card expires before the subscription start date.",
  [ANETCode.E_E00019]:
    "The customer tax id or drivers license information is required.",
  [ANETCode.E_E00020]:
    "The payment gateway account is not enabled for eCheck.Net subscriptions.",
  [ANETCode.E_E00021]:
    "The payment gateway account is not enabled for credit card subscriptions.",
  [ANETCode.E_E00022]:
    "The interval length cannot exceed 365 days or 12 months.",
  [ANETCode.E_E00023]: "The subscription duration cannot exceed three years.",
  [ANETCode.E_E00024]:
    "Trial Occurrences is required when Trial Amount is specified.",
  [ANETCode.E_E00025]: "Automated Recurring Billing is not enabled.",
  [ANETCode.E_E00026]: "Both Trial Amount and Trial Occurrences are required.",
  [ANETCode.E_E00027]: "The transaction was unsuccessful.",
  [ANETCode.E_E00028]: "Trial Occurrences must be less than Total Occurrences.",
  [ANETCode.E_E00029]: "Payment information is required.",
  [ANETCode.E_E00030]: "The payment schedule is required.",
  [ANETCode.E_E00031]: "The amount is required.",
  [ANETCode.E_E00032]: "The start date is required.",
  [ANETCode.E_E00033]: "The start date cannot be changed.",
  [ANETCode.E_E00034]: "The interval information cannot be changed.",
  [ANETCode.E_E00035]: "The subscription cannot be found.",
  [ANETCode.E_E00036]: "The payment type cannot be changed.",
  [ANETCode.E_E00037]: "The subscription cannot be updated.",
  [ANETCode.E_E00038]: "The subscription cannot be canceled.",
  [ANETCode.E_E00039]: "A duplicate record already exists.",
  [ANETCode.E_E00040]: "The record cannot be found.",
  [ANETCode.E_E00041]: "One or more fields must contain a value.",
  [ANETCode.E_E00042]: "You cannot add more than {0} payment profiles.",
  [ANETCode.E_E00043]: "You cannot add more than {0} shipping addresses.",
  [ANETCode.E_E00044]: "Customer Information Manager is not enabled.",
  [ANETCode.E_E00045]:
    "The root node does not reference a valid XML namespace.",
  [ANETCode.E_E00046]: "Generic InsertNewMerchant failure.",
  [ANETCode.E_E00047]: "Merchant Boarding API is not enabled.",
  [ANETCode.E_E00048]:
    "At least one payment method must be set in payment types or an echeck service must be provided.",
  [ANETCode.E_E00049]: "The operation timed out before it could be completed.",
  [ANETCode.E_E00050]: "Sell Rates cannot be less than Buy Rates",
  [ANETCode.E_E00051]:
    "The original transaction was not issued for this payment profile.",
  [ANETCode.E_E00052]:
    "The maximum number of elements for an array {0} is {1}.",
  [ANETCode.E_E00053]: "Server too busy",
  [ANETCode.E_E00054]:
    "The mobile device is not registered with this merchant account.",
  [ANETCode.E_E00055]:
    "The mobile device has already been registered but is pending approval by the account administrator.",
  [ANETCode.E_E00056]:
    "The mobile device has been disabled for use with this account.",
  [ANETCode.E_E00057]:
    "The user does not have permissions to submit requests from a mobile device.",
  [ANETCode.E_E00058]:
    "The merchant has met or exceeded the number of pending mobile devices permitted for this account.",
  [ANETCode.E_E00059]:
    "The authentication type is not allowed for this method call.",
  [ANETCode.E_E00060]: "The transaction type is invalid.",
  [ANETCode.E_E00061]: "{0}({1}).",
  [ANETCode.E_E00062]: "Fatal error when calling web service.",
  [ANETCode.E_E00063]: "Calling web service return error.",
  [ANETCode.E_E00064]: "Client authorization denied.",
  [ANETCode.E_E00065]: "Prerequisite failed.",
  [ANETCode.E_E00066]: "Invalid value.",
  [ANETCode.E_E00067]:
    "An error occurred while parsing the XML request.  Too many {0} specified.",
  [ANETCode.E_E00068]:
    "An error occurred while parsing the XML request.  {0} is invalid.",
  [ANETCode.E_E00069]:
    "The Payment Gateway Account service (id&#x3D;8) has already been accepted.  Decline is not allowed.",
  [ANETCode.E_E00070]:
    "The Payment Gateway Account service (id&#x3D;8) has already been declined.  Agree is not allowed.",
  [ANETCode.E_E00071]: "{0} must contain data.",
  [ANETCode.E_E00072]: "Node {0} is required.",
  [ANETCode.E_E00073]: "{0} is invalid.",
  [ANETCode.E_E00074]: "This merchant is not associated with this reseller.",
  [ANETCode.E_E00075]:
    "An error occurred while parsing the XML request.  Missing field(s) {0}.",
  [ANETCode.E_E00076]: "{0} contains invalid value.",
  [ANETCode.E_E00077]:
    "The value of {0} is too long.  The length of value should be {1}",
  [ANETCode.E_E00078]: "Pending Status (not completed).",
  [ANETCode.E_E00079]: "The impersonation login ID is invalid or not present.",
  [ANETCode.E_E00080]: "The impersonation API Key is invalid or not present.",
  [ANETCode.E_E00081]:
    "Partner account is not authorized to impersonate the login account.",
  [ANETCode.E_E00082]: "Country for {0} is not valid.",
  [ANETCode.E_E00083]:
    "Bank payment method is not accepted for the selected business country.",
  [ANETCode.E_E00084]:
    "Credit card payment method is not accepted for the selected business country.",
  [ANETCode.E_E00085]: "State for {0} is not valid.",
  [ANETCode.E_E00086]: "Merchant has declined authorization to resource.",
  [ANETCode.E_E00087]: "No subscriptions found for the given request.",
  [ANETCode.E_E00088]:
    "ProfileIds cannot be sent when requesting CreateProfile.",
  [ANETCode.E_E00089]:
    "Payment data is required when requesting CreateProfile.",
  [ANETCode.E_E00090]: "PaymentProfile cannot be sent with payment data.",
  [ANETCode.E_E00091]: "PaymentProfileId cannot be sent with payment data.",
  [ANETCode.E_E00092]: "ShippingProfileId cannot be sent with ShipTo data.",
  [ANETCode.E_E00093]: "PaymentProfile cannot be sent with billing data.",
  [ANETCode.E_E00094]: "Paging Offset exceeds the maximum allowed value.",
  [ANETCode.E_E00095]:
    "ShippingProfileId is not provided within Customer Profile.",
  [ANETCode.E_E00096]: "Finger Print value is not valid.",
  [ANETCode.E_E00097]: "Finger Print can&#x27;t be generated.",
  [ANETCode.E_E00098]: "Customer Profile ID or Shipping Profile ID not found.",
  [ANETCode.E_E00099]:
    "Customer profile creation failed. This transaction ID is invalid.",
  [ANETCode.E_E00100]:
    "Customer profile creation failed. This transaction type does not support profile creation.",
  [ANETCode.E_E00101]: "Customer profile creation failed.",
  [ANETCode.E_E00102]: "Customer Info is missing.",
  [ANETCode.E_E00103]:
    "Customer profile creation failed. This payment method does not support profile creation.",
  [ANETCode.E_E00104]: "Server in maintenance. Please try again later.",
  [ANETCode.E_E00105]:
    "The specified payment profile is associated with an active or suspended subscription and cannot be deleted.",
  [ANETCode.E_E00106]:
    "The specified customer profile is associated with an active or suspended subscription and cannot be deleted.",
  [ANETCode.E_E00107]:
    "The specified shipping profile is associated with an active or suspended subscription and cannot be deleted.",
  [ANETCode.E_E00108]: "CustomerProfileId cannot be sent with customer data.",
  [ANETCode.E_E00109]: "CustomerAddressId cannot be sent with shipTo data.",
  [ANETCode.E_E00110]:
    "CustomerPaymentProfileId is not provided within Customer Profile.",
  [ANETCode.E_E00111]:
    "The original subscription was not created with this Customer Profile.",
  [ANETCode.E_E00112]: "The specified month should not be in the future.",
  [ANETCode.E_E00113]: "Invalid OTS Token Data.",
  [ANETCode.E_E00114]: "Invalid OTS Token.",
  [ANETCode.E_E00115]: "Expired OTS Token.",
  [ANETCode.E_E00116]: "OTS Token access violation",
  [ANETCode.E_E00117]: "OTS Service Error &#x27;{0}&#x27;",
  [ANETCode.E_E00118]: "The transaction has been declined.",
  [ANETCode.E_E00119]:
    "Payment information should not be sent to Hosted Payment Page request.",
  [ANETCode.E_E00120]:
    "Payment and Shipping Profile IDs cannot be specified when creating new profiles.",
  [ANETCode.E_E00121]: "No default payment/shipping profile found.",
  [ANETCode.E_E00122]:
    "Please use Merchant Interface settings (API Credentials and Keys) to generate a signature key.",
  [ANETCode.E_E00123]: "The provided access token has expired",
  [ANETCode.E_E00124]: "The provided access token is invalid",
  [ANETCode.E_E00125]: "Hash doesnâ€™t match",
  [ANETCode.E_E00126]: "Failed shared key validation",
  [ANETCode.E_E00127]: "Invoice does not exist",
  [ANETCode.E_E00128]: "Requested action is not allowed",
  [ANETCode.E_E00129]: "Failed sending email",
  [ANETCode.E_E00130]: "Valid Customer Profile ID or Email is required",
  [ANETCode.E_E00131]: "Invoice created but not processed completely",
  [ANETCode.E_E00132]: "Invoicing or CIM service is not enabled.",
  [ANETCode.E_E00133]: "Server error.",
  [ANETCode.E_E00134]: "Due date is invalid",
  [ANETCode.E_E00135]: "Merchant has not provided processor information.",
  [ANETCode.E_E00136]:
    "Processor account is still in process, please try again later.",
  [ANETCode.E_E00137]: "Multiple payment types are not allowed.",
  [ANETCode.E_E00138]:
    "Payment and Shipping Profile IDs cannot be specified when requesting a hosted payment page.",
  [ANETCode.E_E00139]:
    "Access denied. Access Token does not have correct permissions for this API.",
  [ANETCode.E_E00140]: "Reference Id not found",
  [ANETCode.E_E00141]:
    "Payment Profile creation with this OpaqueData descriptor requires transactionMode to be set to liveMode.",
  [ANETCode.E_E00142]:
    "RecurringBilling setting is a required field for recurring tokenized payment transactions.",
  [ANETCode.E_E00143]: "Failed to parse MerchantId to integer",
  [ANETCode.E_E00144]:
    "We are currently holding the last transaction for review. Before you reactivate the subscription, review the transaction.",
  [ANETCode.E_E00145]:
    "This invoice has been canceled by the sender. Please contact the sender directly if you have questions. ",
  [ANETCode.E_0]: "Unknown Error.",
  [ANETCode.E_1]: "This transaction has been approved.",
  [ANETCode.E_2]: "This transaction has been declined.",
  [ANETCode.E_3]: "This transaction has been declined.",
  [ANETCode.E_4]: "This transaction has been declined.",
  [ANETCode.E_5]: "A valid amount is required.",
  [ANETCode.E_6]: "The credit card number is invalid.",
  [ANETCode.E_7]: "Credit card expiration date is invalid.",
  [ANETCode.E_8]: "The credit card has expired.",
  [ANETCode.E_9]: "The ABA code is invalid",
  [ANETCode.E_10]: "The account number is invalid",
  [ANETCode.E_11]: "A duplicate transaction has been submitted.",
  [ANETCode.E_12]: "An authorization code is required but not present.",
  [ANETCode.E_13]:
    "The merchant login ID or password is invalid or the account is inactive.",
  [ANETCode.E_14]:
    "The referrer, relay response or receipt link URL is invalid.",
  [ANETCode.E_15]: "The transaction ID is invalid or not present.",
  [ANETCode.E_16]: "The transaction cannot be found.",
  [ANETCode.E_17]: "The merchant does not accept this type of credit card.",
  [ANETCode.E_18]: "ACH transactions are not accepted by this merchant.",
  [ANETCode.E_19]: "An error occurred during processing.  Please try again.",
  [ANETCode.E_20]: "An error occurred during processing.  Please try again.",
  [ANETCode.E_21]: "An error occurred during processing.  Please try again.",
  [ANETCode.E_22]: "An error occurred during processing.  Please try again.",
  [ANETCode.E_23]: "An error occurred during processing.  Please try again.",
  [ANETCode.E_24]:
    "The Elavon bank number or terminal ID is incorrect. Call Merchant Service Provider.",
  [ANETCode.E_25]: "An error occurred during processing.  Please try again.",
  [ANETCode.E_26]: "An error occurred during processing.  Please try again.",
  [ANETCode.E_27]:
    "The transaction has been declined because of an AVS mismatch. The address provided does not match billing address of cardholder.",
  [ANETCode.E_28]: "The merchant does not accept this type of credit card.",
  [ANETCode.E_29]:
    "The Paymentech identification numbers are incorrect. Call Merchant Service Provider.",
  [ANETCode.E_30]:
    "The configuration with processor is invalid. Call Merchant Service Provider.",
  [ANETCode.E_31]:
    "The FDC Merchant ID or Terminal ID is incorrect. Call Merchant Service Provider.",
  [ANETCode.E_32]: "The merchant password is invalid or not present.",
  [ANETCode.E_33]: "%s cannot be left blank.",
  [ANETCode.E_34]:
    "The VITAL identification numbers are incorrect. Call Merchant Service Provider.",
  [ANETCode.E_35]:
    "An error occurred during processing. Call Merchant Service Provider.",
  [ANETCode.E_36]: "The authorization was approved but settlement failed.",
  [ANETCode.E_37]: "The credit card number is invalid.",
  [ANETCode.E_38]:
    "The Global Payment System identification numbers are incorrect. Call Merchant Service Provider.",
  [ANETCode.E_39]:
    "The supplied currency code is either invalid, not supported, not allowed for this merchant or doesnt have an exchange rate.",
  [ANETCode.E_40]: "This transaction must be encrypted.",
  [ANETCode.E_41]: "This transaction has been declined.",
  [ANETCode.E_42]:
    "There is missing or invalid information in a required field.",
  [ANETCode.E_43]:
    "The merchant was incorrectly set up at the processor. Call Merchant Service Provider.",
  [ANETCode.E_44]: "This transaction has been declined.",
  [ANETCode.E_45]: "This transaction has been declined.",
  [ANETCode.E_46]:
    "Your session has expired or does not exist. You must log in again to continue working.",
  [ANETCode.E_47]:
    "The amount requested for settlement cannot be greater than the original amount authorized.",
  [ANETCode.E_48]: "This processor does not accept partial reversals.",
  [ANETCode.E_49]:
    "The transaction amount submitted was greater than the maximum amount allowed.",
  [ANETCode.E_50]:
    "This transaction is awaiting settlement and cannot be refunded.",
  [ANETCode.E_51]:
    "The sum of all credits against this transaction is greater than the original transaction amount.",
  [ANETCode.E_52]:
    "The transaction was authorized but the client could not be notified; it will not be settled.",
  [ANETCode.E_53]: "The transaction type is invalid for ACH transactions.",
  [ANETCode.E_54]:
    "The referenced transaction does not meet the criteria for issuing a credit.",
  [ANETCode.E_55]:
    "The sum of credits against the referenced transaction would exceed original debit amount.",
  [ANETCode.E_56]:
    "Credit card transactions are not accepted by this merchant.",
  [ANETCode.E_57]: "An error occurred during processing.  Please try again.",
  [ANETCode.E_58]: "An error occurred during processing.  Please try again.",
  [ANETCode.E_59]: "An error occurred during processing.  Please try again.",
  [ANETCode.E_60]: "An error occurred during processing.  Please try again.",
  [ANETCode.E_61]: "An error occurred during processing.  Please try again.",
  [ANETCode.E_62]: "An error occurred during processing.  Please try again.",
  [ANETCode.E_63]: "An error occurred during processing.  Please try again.",
  [ANETCode.E_64]: "The referenced transaction was not approved.",
  [ANETCode.E_65]: "This transaction has been declined.",
  [ANETCode.E_66]: "This transaction cannot be accepted for processing.",
  [ANETCode.E_67]:
    "The given transaction type is not supported for this merchant.",
  [ANETCode.E_68]: "The version parameter is invalid",
  [ANETCode.E_69]: "The transaction type is invalid",
  [ANETCode.E_70]: "The transaction method is invalid.",
  [ANETCode.E_71]: "The bank account type is invalid.",
  [ANETCode.E_72]: "The authorization code is invalid.",
  [ANETCode.E_73]: "The drivers license date of birth is invalid.",
  [ANETCode.E_74]: "The duty amount is invalid.",
  [ANETCode.E_75]: "The freight amount is invalid.",
  [ANETCode.E_76]: "The tax amount is invalid.",
  [ANETCode.E_77]: "The SSN or tax ID is invalid.",
  [ANETCode.E_78]: "The card code is invalid.",
  [ANETCode.E_79]: "The drivers license number is invalid.",
  [ANETCode.E_80]: "The drivers license state is invalid.",
  [ANETCode.E_81]: "The requested form type is invalid.",
  [ANETCode.E_82]: "Scripts are only supported in version 2.5.",
  [ANETCode.E_83]:
    "The requested script is either invalid or no longer supported.",
  [ANETCode.E_84]: "The device type is invalid or missing.",
  [ANETCode.E_85]: "The market type is invalid",
  [ANETCode.E_86]: "The Response Format is invalid",
  [ANETCode.E_87]:
    "Transactions of this market type cannot be processed on this system.",
  [ANETCode.E_88]: "Track1 data is not in a valid format.",
  [ANETCode.E_89]: "Track2 data is not in a valid format.",
  [ANETCode.E_90]: "ACH transactions cannot be accepted by this system.",
  [ANETCode.E_91]: "Version 2.5 is no longer supported.",
  [ANETCode.E_92]:
    "The gateway no longer supports the requested method of integration.",
  [ANETCode.E_93]: "A valid country is required.",
  [ANETCode.E_94]: "The shipping state or country is invalid.",
  [ANETCode.E_95]: "A valid state is required.",
  [ANETCode.E_96]: "This country is not authorized for buyers.",
  [ANETCode.E_97]: "This transaction cannot be accepted.",
  [ANETCode.E_98]: "This transaction cannot be accepted.",
  [ANETCode.E_99]: "This transaction cannot be accepted.",
  [ANETCode.E_100]: "The eCheck type parameter is invalid.",
  [ANETCode.E_101]:
    "The given name on the account and/or the account type does not match the actual account.",
  [ANETCode.E_102]: "This request cannot be accepted.",
  [ANETCode.E_103]: "This transaction cannot be accepted.",
  [ANETCode.E_104]: "The transaction is currently under review.",
  [ANETCode.E_105]: "The transaction is currently under review.",
  [ANETCode.E_106]: "The transaction is currently under review.",
  [ANETCode.E_107]: "The transaction is currently under review.",
  [ANETCode.E_108]: "The transaction is currently under review.",
  [ANETCode.E_109]: "The transaction is currently under review.",
  [ANETCode.E_110]: "The transaction is currently under review.",
  [ANETCode.E_111]: "A valid billing country is required.",
  [ANETCode.E_112]: "A valid billing state/province is required.",
  [ANETCode.E_113]: "The commercial card type is invalid.",
  [ANETCode.E_114]:
    "The merchant account is in test mode. This automated payment will not be processed.",
  [ANETCode.E_115]:
    "The merchant account is not active. This automated payment will not be processed.",
  [ANETCode.E_116]: "The authentication indicator is invalid.",
  [ANETCode.E_117]: "The cardholder authentication value is invalid.",
  [ANETCode.E_118]:
    "The combination of card type, authentication indicator and cardholder authentication value is invalid.",
  [ANETCode.E_119]:
    "Transactions having cardholder authentication values cannot be marked as recurring.",
  [ANETCode.E_120]: "An error occurred during processing. Please try again.",
  [ANETCode.E_121]: "An error occurred during processing. Please try again.",
  [ANETCode.E_122]: "An error occurred during processing. Please try again.",
  [ANETCode.E_123]:
    "This account has not been given the permission(s) required for this request.",
  [ANETCode.E_124]: "This processor does not accept recurring transactions.",
  [ANETCode.E_125]: "The surcharge amount is invalid.",
  [ANETCode.E_126]: "The Tip amount is invalid.",
  [ANETCode.E_127]:
    "The transaction resulted in an AVS mismatch. The address provided does not match billing address of cardholder.",
  [ANETCode.E_128]: "This transaction cannot be processed.",
  [ANETCode.E_130]: "This merchant account has been closed.",
  [ANETCode.E_131]: "This transaction cannot be accepted at this time.",
  [ANETCode.E_132]: "This transaction cannot be accepted at this time.",
  [ANETCode.E_141]: "This transaction has been declined.",
  [ANETCode.E_145]: "This transaction has been declined.",
  [ANETCode.E_152]:
    "The transaction was authorized but the client could not be notified; it will not be settled.",
  [ANETCode.E_153]: "There was an error processing the payment data.",
  [ANETCode.E_154]:
    "Processing Apple Payments is not enabled for this merchant account.",
  [ANETCode.E_155]:
    "This processor does not support this method of submitting payment data.",
  [ANETCode.E_156]:
    "The cryptogram is either invalid or cannot be used in combination with other parameters.",
  [ANETCode.E_165]: "This transaction has been declined.",
  [ANETCode.E_170]:
    "An error occurred during processing. Please contact the merchant.",
  [ANETCode.E_171]:
    "An error occurred during processing. Please contact the merchant.",
  [ANETCode.E_172]:
    "An error occurred during processing. Please contact the merchant.",
  [ANETCode.E_173]:
    "An error occurred during processing. Please contact the merchant.",
  [ANETCode.E_174]:
    "The transaction type is invalid. Please contact the merchant.",
  [ANETCode.E_175]: "This processor does not allow voiding of credits.",
  [ANETCode.E_180]: "An error occurred during processing.  Please try again.",
  [ANETCode.E_181]: "An error occurred during processing.  Please try again.",
  [ANETCode.E_182]: "One or more of the sub-merchant values are invalid.",
  [ANETCode.E_183]:
    "One or more of the required sub-merchant values are missing.",
  [ANETCode.E_184]: "Invalid Token Requestor Name.",
  [ANETCode.E_185]: "This transaction cannot be processed.",
  [ANETCode.E_186]: "Invalid Token Requestor ID Length.",
  [ANETCode.E_187]: "Invalid Token Requestor ECI Length.",
  [ANETCode.E_191]: "This transaction has been declined.",
  [ANETCode.E_192]: "An error occurred during processing. Please try again.",
  [ANETCode.E_193]: "The transaction is currently under review.",
  [ANETCode.E_195]:
    "One or more of the HTML type configuration fields do not appear to be safe.",
  [ANETCode.E_200]: "This transaction has been declined",
  [ANETCode.E_201]: "This transaction has been declined",
  [ANETCode.E_202]: "This transaction has been declined",
  [ANETCode.E_203]: "This transaction has been declined",
  [ANETCode.E_204]: "This transaction has been declined",
  [ANETCode.E_205]: "This transaction has been declined",
  [ANETCode.E_206]: "This transaction has been declined.",
  [ANETCode.E_207]: "This transaction has been declined",
  [ANETCode.E_208]: "This transaction has been declined",
  [ANETCode.E_209]: "This transaction has been declined",
  [ANETCode.E_210]: "This transaction has been declined",
  [ANETCode.E_211]: "This transaction has been declined",
  [ANETCode.E_212]: "This transaction has been declined",
  [ANETCode.E_213]: "This transaction has been declined",
  [ANETCode.E_214]: "This transaction has been declined",
  [ANETCode.E_215]: "This transaction has been declined",
  [ANETCode.E_216]: "This transaction has been declined",
  [ANETCode.E_217]: "This transaction has been declined",
  [ANETCode.E_218]: "This transaction has been declined",
  [ANETCode.E_219]: "This transaction has been declined",
  [ANETCode.E_220]: "This transaction has been declined",
  [ANETCode.E_221]: "This transaction has been declined",
  [ANETCode.E_222]: "This transaction has been declined",
  [ANETCode.E_223]: "This transaction has been declined",
  [ANETCode.E_224]: "This transaction has been declined",
  [ANETCode.E_225]: "This transaction cannot be processed.",
  [ANETCode.E_226]: "This transaction cannot be processed.",
  [ANETCode.E_227]: "This transaction cannot be processed.",
  [ANETCode.E_228]: "This transaction cannot be processed.",
  [ANETCode.E_229]: "Conversion rate for this card is available.",
  [ANETCode.E_230]: "This transaction cannot be processed.",
  [ANETCode.E_231]: "This transaction cannot be processed.",
  [ANETCode.E_232]: "This transaction cannot be processed.",
  [ANETCode.E_233]: "This transaction cannot be processed.",
  [ANETCode.E_234]: "This transaction cannot be processed.",
  [ANETCode.E_235]: "This transaction cannot be processed.",
  [ANETCode.E_236]: "This transaction cannot be processed.",
  [ANETCode.E_237]: "The referenced transaction has expired.",
  [ANETCode.E_238]: "This transaction cannot be processed.",
  [ANETCode.E_239]: "This transaction cannot be processed.",
  [ANETCode.E_240]: "This transaction cannot be processed.",
  [ANETCode.E_241]: "This transaction cannot be processed.",
  [ANETCode.E_242]: "This transaction cannot be processed.",
  [ANETCode.E_243]:
    "Recurring billing is not allowed for this eCheck.Net type.",
  [ANETCode.E_244]:
    "This eCheck.Net type is not allowed for this Bank Account Type.",
  [ANETCode.E_245]:
    "This eCheck.Net type is not allowed when using the payment gateway hosted payment form.",
  [ANETCode.E_246]: "This eCheck.Net type is not allowed.",
  [ANETCode.E_247]: "This eCheck.Net type is not allowed.",
  [ANETCode.E_248]: "The check number is invalid.",
  [ANETCode.E_250]: "This transaction has been declined.",
  [ANETCode.E_251]: "This transaction has been declined.",
  [ANETCode.E_252]:
    "Your order has been received. Thank you for your business!",
  [ANETCode.E_253]:
    "Your order has been received. Thank you for your business!",
  [ANETCode.E_254]: "This transaction has been declined.",
  [ANETCode.E_260]: "Reversal not supported for this transaction type.",
  [ANETCode.E_261]: "An error occurred during processing.  Please try again.",
  [ANETCode.E_262]: "The PayformMask is invalid.",
  [ANETCode.E_265]: "This transaction cannot be processed.",
  [ANETCode.E_270]: "Line item %1 is invalid.",
  [ANETCode.E_271]:
    "The number of line items submitted is not allowed. A maximum of %1 line items can be submitted.",
  [ANETCode.E_280]: "The auction platform name is invalid.",
  [ANETCode.E_281]: "The auction platform ID is invalid.",
  [ANETCode.E_282]: "The auction listing type is invalid.",
  [ANETCode.E_283]: "The auction listing ID is invalid.",
  [ANETCode.E_284]: "The auction seller ID is invalid.",
  [ANETCode.E_285]: "The auction buyer ID is invalid.",
  [ANETCode.E_286]: "One or more required auction values were not submitted.",
  [ANETCode.E_287]:
    "The combination of auction platform ID and auction platform name is invalid.",
  [ANETCode.E_288]: "This transaction cannot be accepted.",
  [ANETCode.E_289]:
    "This processor does not accept zero dollar authorization for this card type.",
  [ANETCode.E_290]: "There is one or more missing or invalid required fields.",
  [ANETCode.E_295]:
    "The amount of this request was only partially approved on the given prepaid card. An additional payment is required to fulfill the balance of this transaction.",
  [ANETCode.E_296]: "The specified SplitTenderID is invalid.",
  [ANETCode.E_297]:
    "Transaction ID and Split Tender ID cannot both be used in the same request.",
  [ANETCode.E_298]:
    "This order has already been released or voided therefore new transaction associations cannot be accepted.",
  [ANETCode.E_300]: "The device ID is invalid.",
  [ANETCode.E_301]: "The device batch ID is invalid.",
  [ANETCode.E_302]: "The reversal flag is invalid.",
  [ANETCode.E_303]: "The device batch is full. Please close the batch.",
  [ANETCode.E_304]: "The original transaction is in a closed batch.",
  [ANETCode.E_305]: "The merchant is configured for auto-close.",
  [ANETCode.E_306]: "The batch is already closed.",
  [ANETCode.E_307]: "The reversal was processed successfully.",
  [ANETCode.E_308]: "Original transaction for reversal not found.",
  [ANETCode.E_309]: "The device has been disabled.",
  [ANETCode.E_310]: "This transaction has already been voided.",
  [ANETCode.E_311]: "This transaction has already been captured.",
  [ANETCode.E_312]: "The specified security code was invalid.",
  [ANETCode.E_313]: "A new security code was requested.",
  [ANETCode.E_314]: "This transaction cannot be processed.",
  [ANETCode.E_315]: "The credit card number is invalid.",
  [ANETCode.E_316]: "Credit card expiration date is invalid.",
  [ANETCode.E_317]: "The credit card has expired.",
  [ANETCode.E_318]: "A duplicate transaction has been submitted.",
  [ANETCode.E_319]: "The transaction cannot be found.",
  [ANETCode.E_320]:
    "The device identifier is either not registered or not enabled.",
  [ANETCode.E_325]:
    "The request data did not pass the required fields check for this application.",
  [ANETCode.E_326]: "The request field(s) are either invalid or missing.",
  [ANETCode.E_327]:
    "The void request failed. Either the original transaction type does not support void, or the transaction is in the process of being settled.",
  [ANETCode.E_328]: "A validation error occurred at the processor.",
  [ANETCode.E_330]: "V.me transactions are not accepted by this merchant.",
  [ANETCode.E_331]: "The x_call_id value is missing.",
  [ANETCode.E_332]: "The x_call_id value is not found or invalid.",
  [ANETCode.E_333]: "A validation error was returned from V.me.",
  [ANETCode.E_334]: "The V.me transaction is in an invalid state.",
  [ANETCode.E_339]:
    "Use x_method to specify method or send only x_call_id or card/account information.",
  [ANETCode.E_340]:
    "V.me by Visa does not support voids on captured or credit transactions. Please allow the transaction to settle, then process a refund for the captured transaction.",
  [ANETCode.E_341]: "The x_discount value is invalid.",
  [ANETCode.E_342]: "The x_giftwrap value is invalid.",
  [ANETCode.E_343]: "The x_subtotal value is invalid.",
  [ANETCode.E_344]: "The x_misc value is invalid.",
  [ANETCode.E_350]:
    "Country must be a valid two or three-character value if specified.",
  [ANETCode.E_351]: "Employee ID must be 1 to %x characters in length.",
  [ANETCode.E_355]: "An error occurred while parsing the EMV data.",
  [ANETCode.E_356]:
    "EMV-based transactions are not currently supported for this processor and card type.",
  [ANETCode.E_357]: "Opaque Descriptor is required.",
  [ANETCode.E_358]: "EMV data is not supported with this transaction type.",
  [ANETCode.E_359]: "EMV data is not supported with this market type.",
  [ANETCode.E_360]: "An error occurred while decrypting the EMV data.",
  [ANETCode.E_361]: "The EMV version is invalid.",
  [ANETCode.E_362]: "The EMV version is required.",
  [ANETCode.E_363]: "The POS Entry Mode value is invalid.",
  [ANETCode.E_370]: "Signature data is too large.",
  [ANETCode.E_371]: "Signature must be PNG formatted data.",
  [ANETCode.E_375]: "Terminal/lane number must be numeric.",
  [ANETCode.E_380]: "KSN is duplicated.",
  [ANETCode.E_901]:
    "This transaction cannot be accepted at this time due to system maintenance.  Please try again later.",
  [ANETCode.E_2000]: "Need payer consent.",
  [ANETCode.E_2001]: "PayPal transactions are not accepted by this merchant.",
  [ANETCode.E_2002]: "PayPal transactions require x_version of at least 3.1.",
  [ANETCode.E_2003]: "Request completed successfully",
  [ANETCode.E_2004]: "Success  URL is required.",
  [ANETCode.E_2005]: "Cancel URL is required.",
  [ANETCode.E_2006]: "Payer ID is required.",
  [ANETCode.E_2007]:
    "This processor does not accept zero dollar authorizations.",
  [ANETCode.E_2008]:
    "The referenced transaction does not meet the criteria for issuing a Continued Authorization.",
  [ANETCode.E_2009]:
    "The referenced transaction does not meet the criteria for issuing a Continued Authorization  w/ Auto Capture.",
  [ANETCode.E_2100]: "PayPal transactions require valid URL for success_url",
  [ANETCode.E_2101]: "PayPal transactions require valid URL for cancel_url",
  [ANETCode.E_2102]:
    "Payment not authorized.  Payment has not been authorized by the user.",
  [ANETCode.E_2103]: "This transaction has already been authorized.",
  [ANETCode.E_2104]:
    "The totals of the cart item amounts do not match order amounts. Be sure the total of the payment detail item parameters add up to the order total.",
  [ANETCode.E_2105]: "PayPal has rejected the transaction.Invalid Payer ID.",
  [ANETCode.E_2106]: "PayPal has already captured this transaction.",
  [ANETCode.E_2107]:
    "PayPal has rejected the transaction. This Payer ID belongs to a different customer.",
  [ANETCode.E_2108]:
    "PayPal has rejected the transaction. x_paypal_hdrimg exceeds maximum allowable length.",
  [ANETCode.E_2109]:
    "PayPal has rejected the transaction. x_paypal_payflowcolor must be a 6 character hexadecimal value.",
  [ANETCode.E_2200]:
    "The amount requested for settlement cannot be different than the original amount authorized. Please void transaction if required",
};
