import { platform } from "../mobileHelpers";

declare global {
  interface Window {
    sendToMobile: typeof sendToMobile;
  }
}

export enum MobileActionType {
  GetSafeArea = "GetSafeArea",
  Print = "Print",
}

interface MobileActionMeta {
  [MobileActionType.GetSafeArea]: {
    return: void;
    params: void;
  };
  [MobileActionType.Print]: {
    params: {
      message: string;
    };
    return: string;
  };
}

export interface GenericMobileAction<T extends MobileActionType> {
  type: T;
  params: MobileActionMeta[T]["params"];
}

type MobileAction = {
  [K in keyof typeof MobileActionType]: GenericMobileAction<
    (typeof MobileActionType)[K]
  >;
}[keyof typeof MobileActionType];

export function sendToMobile(action: MobileAction) {
  const json = JSON.stringify(action);
  if (platform === "ios") {
    window.webkit?.messageHandlers.actionHandler.postMessage(json);
  } else if (platform === "android") {
    window.Android?.fromWebView(json);
  }
}

window.sendToMobile = sendToMobile;
