import { useEffect } from "react";
import styles from "./Blog.module.css";
import { Outlet } from "react-router-dom";
import { BlogData } from "../../../lib/blogHelpers";
import { Loader } from "../../../components/Loader/Loader";
import { useReq } from "@larner.dev/use-req";
import { cmsRequest } from "../../../lib/cmsHelpers";

export const Blog = () => {
  const [request, requestState] = useReq<BlogData>(cmsRequest);
  useEffect(() => {
    request.get("/api/blog-posts?populate[0]=author&populate[1]=image");
  }, [request]);
  return (
    <>
      {!requestState.result || requestState.loading ? (
        <div className={styles.loadingContainer}>
          <Loader />
        </div>
      ) : (
        <div className={styles.content}>
          <Outlet
            context={{
              ...requestState.result,
              data: requestState.result.data.sort(
                (a, b) =>
                  new Date(b.attributes.date).getTime() -
                  new Date(a.attributes.date).getTime()
              ),
            }}
          />
        </div>
      )}
    </>
  );
};
