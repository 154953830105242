import dayjs, { ConfigType } from "dayjs";

export const getDateRangeString = (start: ConfigType, end: ConfigType) => {
  let s = dayjs(start);
  let e = dayjs(end);
  if (e < s) {
    const tmp = s;
    s = e;
    e = tmp;
  }
  let rangeString = "";
  if (s.isSame(e, "day")) {
    rangeString = s.format("dddd, MMM D") + " ";
    if (s.format("a") === e.format("a")) {
      rangeString += `${s.format("h:mm")} - ${e.format("h:mma")}`;
    } else {
      rangeString += `${s.format("h:mma")} - ${e.format("h:mma")}`;
    }
  } else {
    rangeString = `${s.format("dddd, MMM D h:mma")} - ${e.format(
      "dddd, MMM D h:mma"
    )}`;
  }
  return rangeString;
};
