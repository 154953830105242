import classNames from "classnames";
import DatePicker from "react-date-picker";

import styles from "./DateField.module.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

interface OldDateFieldProps {
  className?: string;
  size?: "medium" | "large";
  fullWidth?: boolean;
  onChange?: (value: string) => void;
  value?: string;
  error?: string;
  autoFocus?: boolean;
  name?: string;
}

export function OldDateField({
  className,
  size,
  fullWidth,
  error,
  autoFocus,
  onChange,
  value,
  name,
}: OldDateFieldProps) {
  size = size || "medium";

  const classes = classNames(
    styles.content,
    styles[`${size}Size`],
    {
      [styles.fullWidth]: fullWidth,
    },
    className
  );

  return (
    <div className={classes}>
      <DatePicker
        onChange={(val) =>
          onChange && onChange(val ? (val as Date).toISOString() : "")
        }
        value={value}
        autoFocus={autoFocus}
        className={styles.picker}
        name={name}
      />
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
}
