import classNames from "classnames";
import { CSSProperties } from "react";
import styles from "./Button.module.css";
import { Link } from "react-router-dom";
import { Loader } from "../Loader/Loader";
import { HashLink } from "react-router-hash-link";

interface ButtonProps {
  label: string;
  className?: string;
  style?: CSSProperties;
  size: "xx-small" | "x-small" | "small" | "medium" | "large";
  fullWidth?: boolean;
  href?: string;
  onClick?: React.MouseEventHandler;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  outline?: boolean;
  loading?: boolean;
  component?: "link" | "hash-link";
}

export function Button({
  label,
  className,
  size,
  fullWidth,
  href,
  onClick,
  style,
  type,
  disabled,
  outline,
  loading,
  component,
}: ButtonProps) {
  const props = {
    style,
    className: classNames(styles.content, className, {
      [styles.small]: size === "small",
      [styles.medium]: size === "medium",
      [styles["x-small"]]: size === "x-small",
      [styles["xx-small"]]: size === "xx-small",
      [styles.large]: size === "large",
      [styles.fullWidth]: fullWidth,
      [styles.outline]: outline,
      [styles.disabled]: disabled,
    }),
    disabled,
  };
  if (href && !loading) {
    return component === "hash-link" ? (
      <HashLink {...props} to={href} onClick={onClick}>
        {label}
      </HashLink>
    ) : (
      <Link {...props} to={href} onClick={onClick}>
        {label}
      </Link>
    );
  }
  return (
    <button {...props} type={type || "button"} onClick={onClick}>
      {loading ? <Loader /> : label}
    </button>
  );
}
