import {
  HeadingOwnProps,
  Pane,
  PaneOwnProps,
  Paragraph,
  ParagraphOwnProps,
  PolymorphicBoxProps,
} from "evergreen-ui";
import { UIProps } from "../../lib/UIProps";
import { useGFTheme } from "../../lib/themes";
import { H1 } from "../Heading/Heading";

type PageHeaderProps = UIProps & PolymorphicBoxProps<"div", PaneOwnProps>;

const RawPageHeader = (props: PageHeaderProps) => {
  const { colors } = useGFTheme();
  return (
    <Pane
      backgroundColor={colors.greenLight}
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingX="1.5rem"
      paddingY="2rem"
      gap="2rem"
      borderBottom={`2px solid ${colors.neutralDark}`}
      {...props}
    />
  );
};

const Title = (
  props: PolymorphicBoxProps<"h1" | "h2" | "h3", HeadingOwnProps>
) => {
  const { colors } = useGFTheme();
  return (
    <H1
      color={colors.greenDark}
      textAlign="center"
      selectors={{ "&::first-line": { color: colors.neutralLight } }}
      {...props}
    />
  );
};

const Subtitle = (props: PolymorphicBoxProps<"p", ParagraphOwnProps>) => {
  const { colors } = useGFTheme();
  return (
    <Paragraph
      is="div"
      size={500}
      color={colors.greenDark}
      textAlign="center"
      {...props}
    />
  );
};

RawPageHeader.Title = Title;
RawPageHeader.Subtitle = Subtitle;

export const PageHeader = RawPageHeader;
