import { useEffect, useState } from "react";
import { ANETCode, ANETErrorMap } from "./authorizeErrors";

export interface Accept {
  dispatchData: (
    data: AcceptSecureData,
    callback: (response: AcceptResponse) => void
  ) => void;
}

export interface AcceptSecureData {
  authData: {
    clientKey: string;
    apiLoginID: string;
  };
  cardData?: {
    cardNumber: string;
    month: string;
    year: string;
    cardCode: string;
  };
  bankData?: {
    routingNumber: string;
    accountNumber: string;
    nameOnAccount: string;
    accountType: string;
    echeckType: string;
  };
}

export interface AcceptResponse {
  messages: {
    resultCode: string;
    message: Array<{
      code: ANETCode;
      text: string;
    }>;
  };
  opaqueData?: {
    dataDescriptor: string;
    dataValue: string;
  };
}

export const loadAuthorizeAccept = (): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const existingScript = document.getElementById("accept-js");

    if (!existingScript) {
      const script = document.createElement("script");
      script.src = import.meta.env.VITE_ANET_ACCEPTJS_URL;
      script.id = "accept-js";
      script.type = "text/javascript";
      script.charset = "utf-8";
      script.async = true;
      script.onload = () => {
        resolve();
      };
      script.onerror = () =>
        reject(new Error("Failed to load the Accept.js script."));
      document.head.appendChild(script);
    } else {
      resolve();
    }
  });
};

export const useLoadAccept = () => {
  const [state, setState] = useState({
    loading: !document.getElementById("accept-js"),
    error: "",
  });
  useEffect(() => {
    loadAuthorizeAccept()
      .then(() => {
        setState({ loading: false, error: "" });
      })
      .catch((error) => ({ loading: false, error: error.toString() }));
  }, []);
  return state;
};

export const getANetErrorMessage = (code?: string): string => {
  if (code && code in ANETErrorMap) {
    return ANETErrorMap[code as ANETCode];
  }
  return "Unknown credit card processing error.";
};
