import classNames from "classnames";
import { CSSProperties, useCallback } from "react";
import styles from "./TextField.module.css";

interface TextFieldProps {
  className?: string;
  conatinerClassName?: string;
  placeholder?: string;
  style?: CSSProperties;
  multiLine?: boolean;
  size?: "medium" | "large";
  fullWidth?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value?: string;
  error?: string;
  name?: string;
  autoFocus?: boolean;
  required?: boolean;
  type?: "text" | "number" | "date";
}

export function TextField({
  style,
  placeholder,
  className,
  conatinerClassName,
  multiLine,
  size,
  fullWidth,
  error,
  autoFocus,
  type,
  ...props
}: TextFieldProps) {
  size = size || "medium";

  const classes = classNames(
    styles.content,
    styles[`${size}Size`],
    {
      [styles.multiLine]: multiLine,
      [styles.fullWidth]: fullWidth,
    },
    className
  );

  type = type || "text";

  const autoFocusRef = useCallback((inputElement: HTMLInputElement) => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  if (autoFocus) {
    (props as { ref: unknown }).ref = autoFocusRef;
  }

  return (
    <div className={conatinerClassName}>
      {multiLine ? (
        <textarea
          className={classes}
          placeholder={placeholder}
          style={style}
          {...props}
        ></textarea>
      ) : (
        <input
          type={type}
          className={classes}
          placeholder={placeholder}
          style={style}
          {...props}
        />
      )}
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
}
