import { FormField, TextInput, TextInputFieldOwnProps } from "evergreen-ui";
import OtpInput from "react-otp-input";

import styles from "./VerificationCode.module.css";
import { useGFTheme } from "../../lib/themes";

interface VerificationCodeProps extends Omit<TextInputFieldOwnProps, "type"> {
  value: string;
  onChange: (value: string) => void;
  length: number;
}
export const VerificationCode = ({
  value,
  onChange,
  length,
  ...props
}: VerificationCodeProps) => {
  const { colors } = useGFTheme();
  return (
    <FormField {...props}>
      <OtpInput
        value={value}
        onChange={onChange}
        numInputs={length || 6}
        containerStyle={styles.verificationCodeContainer}
        shouldAutoFocus
        renderInput={(props) => (
          <TextInput
            size="large"
            {...props}
            style={{ width: "2.6rem", textAlign: "center" }}
            autoComplete="one-time-code"
            borderWidth="2px"
            borderColor={colors.greenDark}
            borderStyle="solid"
            borderRadius="5px"
            width="3.125rem"
            height="3.125rem"
          />
        )}
      />
    </FormField>
  );
};
