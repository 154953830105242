import { Pane } from "evergreen-ui";
import { Loader } from "../Loader/Loader";

export const LoaderScreen = () => (
  <Pane
    width="100%"
    height="100%"
    flex={1}
    alignItems="center"
    justifyContent="center"
    display="flex"
  >
    <Loader />
  </Pane>
);
