import { Pane } from "evergreen-ui";
import { H1 } from "../Heading/Heading";

export const NotFound = () => (
  <Pane
    width="100%"
    height="100%"
    flex={1}
    alignItems="center"
    justifyContent="center"
    display="flex"
  >
    <H1> Page Not Found</H1>
  </Pane>
);
