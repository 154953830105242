import { Pane, FormField, TextInput, TextInputField } from "evergreen-ui";
import { useCallback } from "react";
import cardLogos from "./images/card_logos.svg";
import { LinkButton } from "../LinkButton/LinkButton";
import { Alert } from "../Alert/Alert";

export interface CreditCardFormErrors {
  cardNumber?: string;
  cvc?: string;
  name?: string;
  expDate?: string;
  authorize?: string;
  postalCode?: string;
}

export interface CreditCardFormValue {
  cardNumber: string;
  cvc: string;
  name: string;
  expDate: string;
  postalCode: string;
}

interface CreditCardFormProps {
  value: CreditCardFormValue;
  onChange: (value: CreditCardFormValue) => void;
  errors?: CreditCardFormErrors;
  onCancel?: () => void;
}

export const CreditCardForm = ({
  value,
  errors,
  onChange,
  onCancel,
}: CreditCardFormProps) => {
  const change = useCallback(
    (val: Partial<CreditCardFormValue>) => {
      const newVal = { ...value, ...val };
      onChange(newVal);
    },
    [onChange, value]
  );
  return (
    <Pane
      is="form"
      //   onSubmit={pay}
      display="flex"
      flexDirection="column"
      alignItems="center"
      selectors={{
        "&>div": {
          width: "100%",
        },
      }}
    >
      {errors?.authorize && (
        <Alert
          intent="danger"
          title={errors.authorize}
          style={{ marginBottom: "1rem" }}
        />
      )}
      <FormField
        label={
          <Pane display="flex" justifyContent="space-between">
            <Pane>Card Information</Pane>
            {onCancel && <LinkButton onClick={onCancel}>Cancel</LinkButton>}
          </Pane>
        }
        validationMessage={errors?.cardNumber || errors?.cvc || errors?.expDate}
        display="flex"
        flexDirection="column"
        marginBottom="1.5rem"
      >
        <Pane
          position="relative"
          selectors={{
            "&:focus": {
              boxShadow: "0",
              borderColor: "colors.greenLight",
              height: "2.875rem",
            },
          }}
        >
          <TextInput
            placeholder="1234 1234 1234 1234"
            width="100%"
            autoComplete="cc-number"
            paddingRight="148px"
            borderBottomLeftRadius={0}
            borderBottomRightRadius={0}
            value={value.cardNumber}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              // Allow: backspace, delete, tab, escape, enter, and arrow keys
              if (e.metaKey || e.ctrlKey || e.key.length > 1) {
                // Let it happen, don't do anything
                return;
              }

              // Ensure that it is a number key (digits 0-9)
              if (e.key < "0" || e.key > "9") {
                e.preventDefault(); // Ignore the key press
              }
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const strippedSpaces = e.target.value.replace(/ /g, "");
              let result = "";
              for (let i = 0; i < strippedSpaces.length; i += 4) {
                result += strippedSpaces.slice(i, i + 4) + " ";
              }
              change({ cardNumber: result.trim().substring(0, 19) });
            }}
          />
          <Pane
            is="img"
            src={cardLogos}
            height="32px"
            width="144px"
            position="absolute"
            right="4px"
            top="7px"
          />
        </Pane>

        <Pane display="flex">
          <TextInput
            placeholder="MM / YY"
            autoComplete="cc-exp"
            borderTop="0"
            borderRadius="0"
            borderBottomLeftRadius="5px"
            width="50%"
            value={value.expDate}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Backspace" || e.key === "Delete") {
                if (value.expDate?.match(/^[0-9]{2} \/ [0-9]?$/)) {
                  e.preventDefault(); // Ignore the key press
                  change({ expDate: value.expDate.substring(0, 2) });
                }
              }
              // Allow: backspace, delete, tab, escape, enter, and arrow keys
              if (e.metaKey || e.ctrlKey || e.key.length > 1) {
                // Let it happen, don't do anything
                return;
              }

              // Ensure that it is a number key (digits 0-9)
              if (e.key < "0" || e.key > "9") {
                e.preventDefault(); // Ignore the key press
              }
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const stripped = e.target.value.replace(/[ /]/g, "");
              let m = stripped.substring(0, 2);
              const y = stripped.substring(2, 4);
              if (m?.length === 1 && m > "1") {
                m = "0" + m;
              }
              let final = "";
              if (m.length === 2) {
                final = m + " / " + (y || "");
              } else {
                final = m;
              }
              change({ expDate: final });
            }}
          />
          <TextInput
            placeholder="CVC"
            autoComplete="cc-csc"
            borderTop="0"
            borderRadius="0"
            borderBottomRightRadius="5px"
            borderLeft="0"
            width="50%"
            value={value.cvc}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              // Allow: backspace, delete, tab, escape, enter, and arrow keys
              if (e.metaKey || e.ctrlKey || e.key.length > 1) {
                // Let it happen, don't do anything
                return;
              }

              // Ensure that it is a number key (digits 0-9)
              if (e.key < "0" || e.key > "9") {
                e.preventDefault(); // Ignore the key press
              }
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              change({ cvc: e.target.value.substring(0, 4) });
            }}
          />
        </Pane>
      </FormField>
      <TextInputField
        label={<>Cardholder Name</>}
        validationMessage={errors?.name}
        value={value.name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          change({ name: e.target.value })
        }
      />

      <TextInputField
        label={<>Zip Code</>}
        autoComplete="billing postal-code"
        validationMessage={errors?.postalCode}
        value={value.postalCode}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          // Allow: backspace, delete, tab, escape, enter, and arrow keys
          if (e.metaKey || e.ctrlKey || e.key.length > 1) {
            // Let it happen, don't do anything
            return;
          }

          // Ensure that it is a number key (digits 0-9)
          if (e.key < "0" || e.key > "9") {
            e.preventDefault(); // Ignore the key press
          }
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          change({ postalCode: e.target.value })
        }
      />
    </Pane>
  );
};
