import classNames from "classnames";
import {
  Alert,
  Button,
  Dialog,
  Pane,
  Paragraph,
  TextInputField,
} from "evergreen-ui";
import { FormEventHandler, useCallback, useRef, useState } from "react";

import styles from "./LoginDialog.module.css";
import { store, useStoreData } from "../../lib/store/store";
import { ActionType } from "../../lib/store/storeActions";
import {
  PublicCredentialRecord,
  UserWithMediaAndGroups,
} from "@greenflagdate/shared";
import { useReq } from "@larner.dev/use-req";
import { apiReq } from "../../lib/apiReq";
import { VerificationCode } from "../VerificationCode/VerificationCode";
import isEmail from "validator/lib/isEmail";
import { H2 } from "../Heading/Heading";

interface LoginDialogProps {
  isShown: boolean;
  onClose: () => void;
  source?: string;
}

type LoginFormErrors = {
  email?: string;
};

type VerifyFormErrors = {
  email?: string;
  code?: string;
};

export const LoginDialog = ({ isShown, onClose, source }: LoginDialogProps) => {
  const [formStage, setFormStage] = useState(0);
  const storeData = useStoreData();

  const loginForm = useRef<HTMLFormElement>(null);
  const [email, setEmail] = useState("");
  const [loginErrors, setLoginErrors] = useState<LoginFormErrors>({});
  const [loginRequest, loginRequestState] =
    useReq<PublicCredentialRecord>(apiReq);
  const login = useCallback<FormEventHandler>(
    async (e) => {
      e.preventDefault();
      const data = new FormData(loginForm.current!);
      const errors: LoginFormErrors = {};
      const emailFromForm = data.get("email")?.toString();
      if (!emailFromForm) {
        errors.email = "Please enter your email";
      } else if (!isEmail(emailFromForm)) {
        errors.email = "Please double check your email";
      }

      setLoginErrors(errors);
      if (!Object.keys(errors).length) {
        loginRequestState.clearError();
        const response = await loginRequest.post("/user", {
          email: emailFromForm,
        });
        if (response.success) {
          store.dispatch({
            type: ActionType.UpdateAuth,
            params: {
              emailCredentialId: response.result.id,
            },
          });
          setFormStage(1);
          setTimeout(() => setFormStage(2), 500);
        }
      }
    },
    [loginRequest, loginRequestState]
  );

  const verifyForm = useRef<HTMLFormElement>(null);
  const [verifyErrors, setVerifyErrors] = useState<VerifyFormErrors>({});
  const [verifyRequest, verifyRequestState] = useReq<{
    token: string;
    user: UserWithMediaAndGroups;
  }>(apiReq);
  const [otp, setOtp] = useState("");
  const closeDialog = useCallback(() => {
    setFormStage(0);
    setLoginErrors({});
    setVerifyErrors({});
    setOtp("");
    loginRequestState.clearError();
    verifyRequestState.clearError();
    onClose();
  }, [loginRequestState, onClose, verifyRequestState]);
  const verify = useCallback(
    async (otpOverride?: string) => {
      if (!storeData.auth.emailCredentialId) {
        setFormStage(2);
        setTimeout(() => setFormStage(1), 500);
        return;
      }
      const errors: VerifyFormErrors = {};
      if (!email) {
        errors.email = "Please enter your email";
      } else if (!isEmail(email)) {
        errors.email = "Please double check your email";
      }

      const otpVal = otpOverride || otp;

      if (!otpVal) {
        errors.code = "Please enter your code";
      }

      if (errors.email) {
        setLoginErrors({ email: errors.email });
        setFormStage(1);
        setTimeout(() => setFormStage(0), 500);
      } else if (errors.code) {
        setVerifyErrors(errors);
      } else {
        verifyRequestState.clearError();
        const response = await verifyRequest.post(
          `/user/verify${source ? `?source=${source}` : ""}`,
          {
            token: `${otpVal}.${storeData.auth.emailCredentialId}`,
          }
        );

        if (response.success) {
          const { result } = response;
          store.dispatch({
            type: ActionType.Login,
            params: result,
          });
          closeDialog();
        }
      }
    },
    [
      storeData.auth.emailCredentialId,
      email,
      otp,
      verifyRequestState,
      verifyRequest,
      source,
      closeDialog,
    ]
  );

  const onOtpChange = useCallback(
    (otpVal: string) => {
      setOtp(otpVal);
      if (otpVal.length === 6) {
        verify(otpVal);
      }
    },
    [verify]
  );

  return (
    <Dialog
      isShown={isShown}
      title="SIGN IN"
      onCloseComplete={closeDialog}
      confirmLabel="Sign in"
      hasFooter={false}
    >
      <Pane overflow="hidden">
        <Pane
          display="flex"
          className={classNames(
            styles.formStage,
            styles[`formStage${formStage}`]
          )}
        >
          {[0, 1].includes(formStage) && (
            <Pane
              ref={loginForm}
              flex={1}
              paddingX="0.25rem"
              boxSizing="border-box"
              is="form"
              onSubmit={login}
            >
              <Alert
                visibility={loginRequestState.error ? "visible" : "hidden"}
                intent="danger"
                title="There was a problem logging in"
                marginBottom="1rem"
              />
              <TextInputField
                label="Email"
                name="email"
                isInvalid={!!loginErrors.email}
                validationMessage={loginErrors.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
              />
              <Pane
                display="flex"
                paddingY="2rem"
                className={styles.buttonContainer}
              >
                <Button
                  appearance="minimal"
                  flex={1}
                  onClick={closeDialog}
                  type="button"
                  disabled={loginRequestState.loading}
                >
                  Cancel
                </Button>
                <Button
                  appearance="primary"
                  flex={2}
                  disabled={loginRequestState.loading}
                >
                  Sign in
                </Button>
              </Pane>
            </Pane>
          )}
          {[1, 2].includes(formStage) && (
            <Pane
              flex={1}
              paddingX="0.25rem"
              boxSizing="border-box"
              display="flex"
              flexDirection="column"
              is="form"
              ref={verifyForm}
              onSubmit={(e: React.FormEvent<Element>) => {
                e.preventDefault();
                verify();
              }}
            >
              <Pane
                flex={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap="1rem"
              >
                <H2>Verification Code</H2>
                <Paragraph>
                  Please check your email and enter the code that was sent to
                  you.
                </Paragraph>
                {(verifyErrors.code || verifyRequestState.error) && (
                  <Alert
                    intent="danger"
                    title="There was problem validating the code"
                  >
                    {verifyErrors.code || verifyRequestState.error?.toString()}
                  </Alert>
                )}
                <VerificationCode
                  value={otp}
                  onChange={onOtpChange}
                  length={6}
                />
              </Pane>
              <Pane display="flex" gap="1rem" paddingY="2rem">
                <Button
                  appearance="minimal"
                  flex={1}
                  onClick={onClose}
                  type="button"
                  disabled={verifyRequestState.loading}
                >
                  Cancel
                </Button>
                <Button
                  appearance="primary"
                  flex={2}
                  type="submit"
                  disabled={verifyRequestState.loading}
                >
                  Sign in
                </Button>
              </Pane>
            </Pane>
          )}
        </Pane>
      </Pane>
    </Dialog>
  );
};
