import { useEffect, useMemo } from "react";
import styles from "./Event.module.css";
import { Loader } from "../../../components/Loader/Loader";
import { Outlet, useParams } from "react-router-dom";
import { NotFound } from "../../../components/NotFound/NotFound";
import { Meta } from "../../../components/Meta/Meta";
import { useReq } from "@larner.dev/use-req";
import { cmsRequest } from "../../../lib/cmsHelpers";
import { CMSEventData } from "@greenflagdate/shared";
import { useStoreData } from "../../../lib/store/store";

export const Event = () => {
  const { eventSlug } = useParams();
  const [request, requestState] = useReq<CMSEventData>(cmsRequest);
  const data = useStoreData();
  useEffect(() => {
    request.get(
      `/api/events?filters[slug][$eq]=${eventSlug}&populate[0]=socialShareImage`
    );
  }, [eventSlug, request]);
  const url = useMemo(() => `/event/${eventSlug}`, [eventSlug]);

  if (!requestState.result || requestState.loading) {
    return (
      <div className={styles.loadingContainer}>
        <Loader />
      </div>
    );
  }

  if (!requestState.loading) {
    if (requestState.error) {
      return <div>{requestState.error.toString()}</div>;
    }
    if (!requestState.result?.data?.length) {
      return (
        <div className={styles.loadingContainer}>
          <NotFound />
        </div>
      );
    }
  }

  const event = requestState.result.data[0];

  return (
    <>
      <Meta>
        <title>
          {event.attributes.title}
          {event.attributes.subtitle ? " - " + event.attributes.subtitle : ""}
        </title>
        <meta property="og:title" content={event.attributes.title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://www.greenflagdate.com${url}`}
        />
        {event.attributes.socialShareImage?.data && (
          <meta
            property="og:image"
            content={event.attributes.socialShareImage.data.attributes.url}
          />
        )}
        {event.attributes.socialShareImage?.data && (
          <meta
            property="og:image:width"
            content={event.attributes.socialShareImage.data.attributes.width?.toString()}
          />
        )}
        {event.attributes.socialShareImage?.data && (
          <meta
            property="og:image:height"
            content={event.attributes.socialShareImage.data.attributes.height?.toString()}
          />
        )}
      </Meta>
      <Outlet
        context={{
          event: requestState.result.data[0],
          url,
          user: data.user,
        }}
      />
    </>
  );
};
