import white from "./images/white.svg";
import blue from "./images/blue.svg";
import { BoxComponent, Pane, PaneOwnProps } from "evergreen-ui";

const colors = {
  white,
  blue,
};

interface LoaderProps extends PaneOwnProps {
  size?: number;
  color?: keyof typeof colors;
}

export const Loader: BoxComponent<LoaderProps, "div"> = ({
  size,
  color,
  ...props
}: LoaderProps) => {
  size = size || 60;
  color = color || "blue";
  return (
    <Pane
      is="img"
      src={colors[color]}
      alt="Loading..."
      style={{ width: size, height: size }}
      {...props}
    />
  );
};
