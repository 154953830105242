import classNames from "classnames";
import { CSSProperties, MouseEventHandler, PropsWithChildren } from "react";
import styles from "./Card.module.css";
import { Pane, PaneProps } from "evergreen-ui";
import { UIProps } from "../../lib/UIProps";

import { Link } from "react-router-dom";

interface CardProps {
  className?: string;
  style?: CSSProperties;
  href?: string;
  onClick?: MouseEventHandler;
}

const RawCard = ({
  children,
  style,
  className,
  href,
  onClick,
}: PropsWithChildren<CardProps>) => {
  const props = {
    className: classNames(styles.container, className),
    style,
    href,
    onClick,
  };
  return href ? (
    <Link {...props} to={href}>
      {children}
    </Link>
  ) : (
    <div {...props}>{children}</div>
  );
};

RawCard.Header = function Card2Header({
  children,
  className,
  style,
}: PropsWithChildren<CardProps>) {
  return (
    <div
      className={classNames(styles.headerContainer, className)}
      style={style}
    >
      {children}
    </div>
  );
};

RawCard.Footer = function Card2Footer({
  children,
  className,
  style,
}: PropsWithChildren<CardProps>) {
  return (
    <div
      className={classNames(styles.footerContainer, className)}
      style={style}
    >
      {children}
    </div>
  );
};

RawCard.FooterTitle = function Card2FooterTitle({
  children,
  className,
  style,
}: PropsWithChildren<CardProps>) {
  return (
    <div className={classNames(styles.footerTitle, className)} style={style}>
      {children}
    </div>
  );
};

RawCard.FooterSubtitle = function Card2FooterSubtitle({
  children,
  className,
  style,
}: PropsWithChildren<CardProps>) {
  return (
    <div className={classNames(styles.footerSubtitle, className)} style={style}>
      {children}
    </div>
  );
};

export const Card = RawCard;

interface NewCardProps extends UIProps, PaneProps<"div"> {
  onClick?: MouseEventHandler;
}

export const NewCard = (props: NewCardProps) => {
  return (
    <Pane
      borderRadius="10px"
      padding="1rem"
      borderWidth="2px"
      borderStyle="solid"
      backgroundColor="white"
      boxShadow={props.elevation ? "0px 4px 4px 0px #00000040" : "none"}
      {...props}
    />
  );
};
