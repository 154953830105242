import classNames from "classnames";
import { PropsWithChildren } from "react";
import styles from "./Section.module.css";
import { Heading } from "../../../components/Heading/Heading";

interface SectionProps {
  theme: "primary" | "gray";
  id?: string;
}

interface TitleProps {
  className?: string;
}

export function Section({
  theme,
  children,
  id,
}: PropsWithChildren<SectionProps>) {
  return (
    <section
      className={classNames(styles.content, styles[`${theme}Theme`])}
      id={id}
    >
      {children}
    </section>
  );
}

Section.Title = function SectionTitle({
  children,
  className,
}: PropsWithChildren<TitleProps>) {
  return (
    <Heading size={2} className={classNames(styles.title, className)}>
      {children}
    </Heading>
  );
};
